/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// INIT

const initialState = {
	authToken: "",
	email: "",
	firstName: "",
	lastName: "",
	refreshToken: ""
};

// EXPORT

export default (state = initialState, action) => {
	switch(action.type) {
		case "RESET_USER":
		return {
			...initialState
        };
        case "SET_USER_AUTH_TOKEN":
		return {
			...state,
			authToken: action.payload
		};
		case "SET_USER_EMAIL":
		return {
			...state,
			email: action.payload
		};
		case "SET_USER_FIRST_NAME":
		return {
			...state,
			firstName: action.payload
		};
		case "SET_USER_LAST_NAME":
		return {
			...state,
			lastName: action.payload
		};
		case "SET_USER_RESET_TOKEN":
		return {
			...state,
			resetToken: action.payload
		};
		default:
		return state;
	}
};
