/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import React, {
	useEffect,
	useState
} from "react";
import {
	connect
} from "react-redux";
import {
	useNavigate
} from "react-router-dom";

import {
	LogoWrapper,
	QaWrapper,
	ScreenBodyWrapper,
	ScreenFooterWrapper,
	ScreenHeaderWrapper,
	ScreenInnerWrapper,
	ScreenWrapper,
	TopLogoWrapper
} from "./Faq.styled";

import Button from "../../components/ui/Button";
import Logo from "../../components/ui/Logo";

import i18nHelper from "../../helpers/i18n";
import storeHelper from "../../helpers/store";
import utilsHelper from "../../helpers/utils";

import useApi from "../../hooks/api";

import i18n from "../../i18n";

// INIT

/**
 * mapStateToProps - ...
 * 
 * @param {object} state ...
 */
const mapStateToProps = (state) => {
	return {
		...state
	};
};

// COMPONENT

const Faq = (props) => {
	const [
		checkoutUrl, 
		setCheckoutUrl
	] = useState(null);
	const [
		isRenderReady, 
		setIsRenderReady
	] = useState(false);
	const [
		product, 
		setProduct
	] = useState(null);

	const {
		fetchApi
	} = useApi();
	const navigate = useNavigate();

	// Lifecycle

	// Note - Run on bootstrap
	useEffect(
		() => {
			if(!props.LOCAL.user.authToken) {
				logoutHandler();
			}

			setIsRenderReady(true);

			return () => {};
		}, 
		[]
	);

	// Events

	/**
	 * goToAccount - ...
	 * 
	 * @param {object} e ...
	 */
	const goToAccount = (e) => {
		navigate({
			pathname: "/account"
		});
	};

	/**
	 * logoutHandler - ...
	 * 
	 * @param {object} e ...
	 */
	const logoutHandler = (e) => {
		storeHelper.dispatch("RESET_USER");

		navigate({
			pathname: "/login"
		});
	};
	
	// Render

	/**
	 * render - ...
	 */
	const render = () => {
		let content;

		if(isRenderReady) {
			content = (
				<ScreenInnerWrapper>
					<ScreenHeaderWrapper>
						<LogoWrapper
							onClick={goToAccount}
							>
							<Logo
								name="copdateInsider"
							/>
						</LogoWrapper>
						<div>
							<h2>
								{i18n.t("screens.faq.header.title")}
							</h2>
							{i18nHelper.renderTrans(
								"screens.faq.header.text",
								{},
								{
									p: <p />
								}
							)}
						</div>
					</ScreenHeaderWrapper>
					<ScreenBodyWrapper>
						<QaWrapper>
							<h2>
								WHAT IS A COPDATE INSIDER?
							</h2>
							<p>
								Copdate Insiders are top-tier members of the Copdate Community who have unlocked the highest status of perks and benefits available on our platform and across our network of partners.
							</p>
							<p>
								As an Insider, you have private access to exclusive events and other high-level benefits available only to top-tier Copdate users.
							</p>
						</QaWrapper>
						<QaWrapper>
							<h2>
								WHAT ARE SOME OF THE BENEFITS?
							</h2>
							<p>
							Copdate Insiders get private access to closed events, guaranteed product reservations with direct checkout, locked-in Ws on the most coveted releases & giveaways, invitation-only unpublished drops from your favorite brands, and other exclusive benefits available only to Insiders throughout the year.
							</p>
							<p>
							As an Insider you will also have access to Perks-Pass-Exclusive events, bonus draws, priority selection on qualified events, and more.§
							</p>
							<p>
							In addition, Insiders get first access to our latest updates and can try new features before they are made available to the general public!
							</p>
						</QaWrapper>
						<QaWrapper>
							<h2>
								HOW DOES INSIDER ACCESS WORK?
							</h2>
							<p>
							When Insider Events are available, you will be notified and can use your Insider credentials to unlock access.
							</p>
						</QaWrapper>
						<QaWrapper>
							<h2>
								HOW FREQUENT ARE INSIDER EVENTS?
							</h2>
							<p>
							Insider Events are available several times throughout the year and frequency will increase as the Program expands.
							</p>
							<p>
							You will be notified each time an Insider Event is available to you and can unlock access using your Insider credentials and following the instructions provided.
							</p>
						</QaWrapper>
						<QaWrapper>
							<h2>
								AM I GUARANTEED A “W” EACH INSIDER EVENT?
							</h2>
							<p>
							In most cases, if you are granted access to an Insider Event, your reservation will be secured and the designated product will be available for purchase either via direct checkout link or from a participating merchant partner.
							</p>
							<p>
							Some Insider Events may consist of redeemable perks or other types of benefits in which there are no purchase options available.
							</p>
							<p>
							In all cases, full details and redemption instructions will be provided to you prior to the event.
							</p>
						</QaWrapper>
						<QaWrapper>
							<h2>
								HOW DO I MAINTAIN MY INSIDER STATUS?
							</h2>
							<p>
							Insider Status is offered on a discretionary basis and is subject to each individual meeting the eligibility requirements, which include but are not limited to:
							</p>
							<ol>
								<li>Account activity and transaction history</li>
								<li>Community engagement and interaction</li>
								<li>Perks-Level, status and ranking</li>
								<li>Other Data-driven metrics</li>
							</ol>
							<p>
							All Insider accounts will be reviewed periodically to ensure that eligibility criteria are maintained. Membership and access to the Insider Program can be temporarily suspended or permanently revoked at the sole discretion of Copdate.
							</p>
						</QaWrapper>
						<QaWrapper>
							<h2>
								WHAT IS TRSTD?
							</h2>
							<p>
							TRSTD is a third-party security platform that authenticates customers via multi-step verification before they can access protected releases.
							</p>
							<p>
							We have partnered with TRSTD to provide an additional layer of protection for Insider Events. Our goal in using this platform is to ensure that Insider Events can only be accessed by trusted, verified customers and that products released within the Insider Program can only be purchased by <strong>real people, not bots</strong>.
							</p>
							<p>
							For more info, visit <a
								href="https://www.trstd.ca/"
								>
								trstd.ca
							</a>.
							</p>
						</QaWrapper>
						<small
							style={{
								marginTop: "40px",
								maxWidth: "280px",
								width: "100%"
							}}
							>
								§<a
								href="https://www.copdate.com/terms-of-service"
								>Terms and conditions</a> apply. Void where prohibited.
						</small>
						<small
							style={{
								marginTop: "40px",
								maxWidth: "280px",
								width: "100%"
							}}
							>
							<a
								onClick={goToAccount}
								>Back to Account</a>
						</small>
						<Button
							label={i18n.t("actions.logout")}
							onClick={logoutHandler}
							style={{
								marginTop: "40px",
								maxWidth: "280px",
								width: "100%"
							}}
						/>
					</ScreenBodyWrapper>
					<ScreenFooterWrapper>
						<small>
							Follow
							&nbsp;
							<a
								href="https://www.instagram.com/copdate"
								>
								@copdate
							</a>
							&nbsp;
							for our latest events & Perks Pass Exclusives.
						</small>
					</ScreenFooterWrapper>
				</ScreenInnerWrapper>
			);
		}

		return (
			<ScreenWrapper>
				{content}
			</ScreenWrapper>
		);
	};

	return render();
};

// EXPORT

export default connect(mapStateToProps)(Faq);
