/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// EXPORT

export const colors = {
	black: "#000",
	blueDark: "#073B4C",
	blueLight: "#118AB2",
	green: "#E4F6E7",//"#20BF55", 
		grey: "#757575",
		greyDark: "#666",
		greyDarker: "#444",
		greyDarkest: "#222",
		greyLight: "#999",
		greyLighter: "#BBB",
		greyLightest: "#DDD",
	orange: "#E95635",
	transparent: "transparent",
	white: "#FFF",
	yellow: "#FFC847",






	athensGray: "#F2F3F6", // light grey
	
		blackLight: "#151515",
		blackLighter: "#252525",
		blackLightest: "#353535",
	blumine: "#195A62", // dark blue (darker 0.7)
	chateauGreen: "#36A85E", // green (darker 0.5)
	daintree: "#002C3E", // dark blue
	greenPea: "#1D5B33", // dark green (darker 0.7)
	linen: "#FBEBE9", // light orange
	ottoman: "#E6F7EC", // light green
	polar: "#E7F7F9", // light blue
	serenade: "#FFF3E6", // light yellow
	sunsetOrange: "#FE4D3D", // dark orange
	
		whiteDark: "#F5F5F5",
		whiteDarker: "#E5E5E5",
		whiteDarkest: "#D5D5D5",

	//... to removed

	alto: "#DDD",
	concrete: "#F2F2F2",
	doveGrey: "#666",
	dusty: "#999",
	elephant: "#0C3240",
	fruitSalad: "#4CAF50",
	scienceBlue: "#0073d0",
	malachite: "#0CC260",
	mineShaft: "#222",
	pictonBlue: "#2CBCF0",
	primaryBackground: "#2CBCF0",
	primaryForeground: "#FFF",
	flamingo: "#F44336",
	silver: "#CCC",
	silverChalice: "#AAA",
	tundora: "#444"
};

export const fonts = {
	inter: `"Inter", sans-serif`,
	poppins: `"Poppins", sans-serif`,

	//... to removed

	lora: `"Lora", serif`,
	openSans: `"Open Sans", sans-serif`,
	roboto: `"Roboto", sans-serif`,
	robotoCondensed: `"Jaldi", sans-serif`
};

export const indexes = {
	appLoader: 999,
	notification: 998,
	header: 997,
	sidebar: 996,
	modal: 996,
	popover: 996,


	sticky: 994,
	footer: 993,
	loader: 996
};

export const typo = {
	fontFamilies: {
		lora: `"Lora", serif`,
		openSans: `"Open Sans", sans-serif`,
		poppins: `"Poppins", sans-serif`,
		roboto: `"Roboto", sans-serif`,
		robotoCondensed: `"Jaldi", sans-serif`
	},
	fontSizes: {
		default: "16px",
		h1: "32px",
		h2: "24px",
		large: "18px",
		larger: "20px",
		small: "14px",
		smaller: "12px"
	},
	lineHeight: {
		default: 1.5,
		large: 1.75,
		small: 1.25
	}
};


