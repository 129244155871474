/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import styled from "styled-components";

import {
	columnWrapper,
	rowWrapper
} from "../../styled/styled-extends";
import {
	setFont
} from "../../styled/styled-mixins";
import {
	colors,
	fonts
} from "../../styled/styled-variables";

// EXPORT

export const ErrorWrapper = styled.small`
	color: #F04438;
`;

export const HelpWrapper = styled.small`
`;

export const LabelWrapper = styled.label`
	color: #747474;
	font-size: 12px;
	font-weight: 500;
`;

export const ComponentWrapper = styled.div`
	align-items: flex-start;
	gap: 4px;
	width: 100%;
	${columnWrapper}
	${setFont(
		fonts.inter,
		"14px",
		400,
		1.5,
		"#999"
	)}
	/*
	label {
		align-items: baseline;
		justify-content: space-between;
		margin-bottom: 8px;
		width: 100%;
		$ {rowWrapper}
		$ {setFont(
			typo.fontFamilies.roboto,
			typo.fontSizes.default,
			500,
			typo.lineHeight.small
		)}
	}

	> small {
		color: #757575;
		margin-top: 8px;
	}

	.error {
		color: #F44336;
	}

	${(props) => {
		const {
			typoColors
		} = props.theme;

		return `
			label {
				color: ${typoColors.level4};

				.optional {
					color: ${typoColors.level6};
				}
			}
		`;
	}}
	*/
`;
