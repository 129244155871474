/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState
} from "react";

import router from "../router";

import storeHelper from "../helpers/store";
import utilsHelper from "../helpers/utils";

import i18n from "../i18n";

// INIT

const appContext = createContext({});

// EXPORT

/**
 * AppProvider - ...
 * 
 * @param {object} props ...
 */
export const AppProvider = ({ children }) => {
	// useCallback or useMemo or ???

	const redirectIfConnected = useCallback((isConnected, callback) => {
		if(!isConnected) {
			if(utilsHelper.isFunction(callback)) {
				callback();
			}
		} else {
			storeHelper.dispatch("SET_APP_NOTIFICATION", {
				autoDismiss: true,
				text: i18n.t("notifications.info.alreadyConnected.text"),
				title: i18n.t("notifications.info.alreadyConnected.title"),
				type: "info"
			});

			historyHelper.goTo(
				[
					screenRoutes.dashboard.urlRoot,
					screenRoutes.dashboard.urls.dashboard
				].join(""),
				"",
				"",
				{},
				true
			);
		}
	});
	const redirectIfNotConnected = useCallback((isConnected, callback) => {
		if(isConnected) {
			if(isFunction(callback)) {
				callback();
			}
		} else {
			storeHelper.dispatch("RESET_USER");
			storeHelper.dispatch("SET_APP_NOTIFICATION", {
				autoDismiss: true,
				text: i18n.t("notifications.error.notConnected.text"),
				title: i18n.t("notifications.error.notConnected.title"),
				type: "danger"
			});

			historyHelper.goTo(
				[
					screenRoutes.auth.urlRoot,
					screenRoutes.auth.urls.signIn
				].join(""),
				"",
				"",
				{
					redirectLocation: history.location
				},
				true
			);
		}
	});
	const redirectIfNotSuperadmin = useCallback((role, callback) => {
		if(role === "superadmin") {
			if(isFunction(callback)) {
				callback();
			}
		} else {
			storeHelper.dispatch("SET_APP_NOTIFICATION", {
				autoDismiss: true,
				text: i18n.t("notifications.info.unauthorized.text"),
				title: i18n.t("notifications.info.unauthorized.title"),
				type: "info"
			});

			historyHelper.goTo(
				[
					screenRoutes.unauthorized.urlRoot,
					screenRoutes.unauthorized.urls.unauthorized
				].join(""),
				"",
				"",
				{},
				true
			);
		}
	});
	const redirectToNotFound = useCallback(() => {
		historyHelper.goToNew(
			{
				pathname: [
					screenRoutes.notFound.urlRoot,
					screenRoutes.notFound.urls.notFound
				].join("")
			}
		);
	});

	/**
	 * windowChangeHandler - ...
	 * 
	 * @param {object} e Event object
	 */
	const windowChangeHandler = useCallback((e) => {
		let type = "mobile";

		if(window.innerWidth >= 1024) {
			type = "desktop";
		} else if(window.innerWidth >= 768) {
			type = "tablet";
		}

		setDevice({
			height: window.innerHeight,
			isDesktop:
				type === "desktop" ? 
				true : 
				false
			,
			isMobile: 
				type === "mobile" ? 
				true : 
				false
			,
			isTablet: 
				type === "tablet" ? 
				true : 
				false
			,
			orientation: 
				window.innerHeight > window.innerWidth ? 
				"portrait" : 
				"landscape"
			,
			width: window.innerWidth
		});
	});

	const [
		device, 
		setDevice
	] = useState({});

	// Lifecycle

	// Note - Run on bootstrap
	useEffect(
		() => {
			window.addEventListener(
				"orientationchange", 
				windowChangeHandler
			);
			window.addEventListener(
				"resize", 
				windowChangeHandler
			);

			windowChangeHandler();

			return () => {
				window.removeEventListener(
					"orientationchange", 
					windowChangeHandler
				);
				window.removeEventListener(
					"resize", 
					windowChangeHandler
				);
			};
		}, 
		[]
	);

	// Render

	return (
		<appContext.Provider
			value={{
				device,
				redirectIfConnected,
				redirectIfNotConnected,
				redirectIfNotSuperadmin,
				redirectToNotFound
			}}
			>
			{children}
		</appContext.Provider>
	);
};

/**
 * useApp - ...
 */
export const useApp = () => {
	const {
		redirectIfConnected,
		redirectIfNotConnected,
		redirectIfNotSuperadmin,
		redirectToNotFound
	} = useContext(appContext);

	return {
		redirectIfConnected,
		redirectIfNotConnected,
		redirectIfNotSuperadmin,
		redirectToNotFound
	};
};

/**
 * useDevice - ...
 */
export const useDevice = () => {
	const {
		device
	} = useContext(appContext);

	return {
		device
	};
};

export default {
	AppProvider,
	useApp,
	useDevice
};
