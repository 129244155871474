/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import PropTypes from "prop-types";
import React from "react";

import {
	ComponentWrapper
} from "./Container.styled";

// INIT

const propTypes = {}; // id, style, ???
const defaultProps = {};

// COMPONENT

const Container = (props) => {
	// Render

	/**
	 * render - ...
	 */
	const render = () => {
		const {
			children,
			...componentWrapperProps
		} = props;

		return (
			<ComponentWrapper
				{...componentWrapperProps}
				>
				{children}
			</ComponentWrapper>
		);  
	};

	return render();
};

Container.propTypes = propTypes;
Container.defaultProps = defaultProps;

// EXPORT

export default Container;
