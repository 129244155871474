/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import PropTypes from "prop-types";
import React from "react";

// INIT

const propTypes = {
	className: PropTypes.string,
	fillColor: PropTypes.string,
	height: PropTypes.string,
	name: PropTypes.string.isRequired,
	noBoundingBox: PropTypes.bool,
	strokeColor: PropTypes.string,
	strokeWidth: PropTypes.string,
	vectorEffect: PropTypes.string,
	width: PropTypes.string
};
const defaultProps = {
	className: "",
	fillColor: "none",
	noBoundingBox: false,
	strokeColor: "#000",
	strokeWidth: "2px",
	vectorEffect: "non-scaling-stroke"
};

// HELPERS

/**
 * getIconProps - ...
 * 
 * @param {object} props List of props
 */
const getIconProps = (props) => {
	let iconProps = {
		svgContent: (
			<g></g>
		)
	};

	if(!props.className) {
		iconProps.className = props.className;
	}

	if(!props.noBoundingBox) {
		iconProps.height = "24px";
	}

	switch(props.name) {
		case "check":
			iconProps.svgContent = (
				<g
					fill={props.fillColor}
					fillRule="evenodd"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={props.strokeWidth}
					>
					<path
						d="M17 1 6 12 1 7"
						stroke={props.strokeColor}
						vectorEffect={props.vectorEffect}
					/>
				</g>
			);
			
			if(props.noBoundingBox) {
				iconProps.height = "13px";
				iconProps.viewBox = "0 0 18 13";
			} else {
				iconProps.viewBox = "-3 -5.5 24 24";
			}
		break;
		case "clock":
			iconProps.svgContent = (
				<g
					fill={props.fillColor}
					fillRule="evenodd"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={props.strokeWidth}
					transform="translate(1 1)"
					>
					<circle
						cx="10" 
						cy="10" 
						r="10"
						stroke={props.strokeColor}
						vectorEffect={props.vectorEffect}
					/>
					<path
						d="M10 4v6l4 2"
						stroke={props.strokeColor}
						vectorEffect={props.vectorEffect}
					/>
				</g>
			);
			
			if(props.noBoundingBox) {
				iconProps.height = "22px";
				iconProps.viewBox = "0 0 22 22";
			} else {
				iconProps.viewBox = "-1 -1 24 24";
			}
		break;
		case "eye":
			iconProps.svgContent = (
				<g
					fill={props.fillColor}
					fillRule="evenodd"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={props.strokeWidth}
					transform="translate(1 1)"
					>
					<circle
						cx="11" 
						cy="8" 
						r="3"
						stroke={props.strokeColor}
						vectorEffect={props.vectorEffect}
					/>
					<path
						d="M0 8s4-8 11-8 11 8 11 8-4 8-11 8S0 8 0 8Z"
						stroke={props.strokeColor}
						vectorEffect={props.vectorEffect}
					/>
				</g>
			);
			
			if(props.noBoundingBox) {
				iconProps.height = "18px";
				iconProps.viewBox = "0 0 24 18";
			} else {
				iconProps.viewBox = "0 -3 24 24";
			}
		break;
		case "eyeOff":
			iconProps.svgContent = (
				<g
					fill={props.fillColor}
					fillRule="evenodd"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={props.strokeWidth}
					>
					<path
						d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.121 9.121 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24M1 1l22 22"
						stroke={props.strokeColor}
						vectorEffect={props.vectorEffect}
					/>
				</g>
			);
			
			if(props.noBoundingBox) {
				iconProps.height = "24px";
				iconProps.viewBox = "0 0 24 24";
			} else {
				iconProps.viewBox = "0 0 24 24";
			}
		break;
		case "eyeOff":
			iconProps.svgContent = (
				<g
					fill={props.fillColor}
					fillRule="evenodd"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={props.strokeWidth}
					>
					<path
						d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.121 9.121 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24M1 1l22 22"
						stroke={props.strokeColor}
						vectorEffect={props.vectorEffect}
					/>
				</g>
			);
			
			if(props.noBoundingBox) {
				iconProps.height = "24px";
				iconProps.viewBox = "0 0 24 24";
			} else {
				iconProps.viewBox = "0 0 24 24";
			}
		break;
		case "maximize":
			iconProps.svgContent = (
				<g
					fill={props.fillColor}
					fillRule="evenodd"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={props.strokeWidth}
					>
					<path
						d="M6 1H3a2 2 0 0 0-2 2v3m18 0V3a2 2 0 0 0-2-2h-3m0 18h3a2 2 0 0 0 2-2v-3M1 14v3a2 2 0 0 0 2 2h3"
						stroke={props.strokeColor}
						vectorEffect={props.vectorEffect}
					/>
				</g>
			);
			
			if(props.noBoundingBox) {
				iconProps.height = "20px";
				iconProps.viewBox = "0 0 20 20";
			} else {
				iconProps.viewBox = "-2 -2 24 24";
			}
		break;
		case "menu":
			iconProps.svgContent = (
				<g
					fill={props.fillColor}
					fillRule="evenodd"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={props.strokeWidth}
					>
					<path
						d="M1 7h18M1 1h18M1 13h18"
						stroke={props.strokeColor}
						vectorEffect={props.vectorEffect}
					/>
				</g>
			);
			
			if(props.noBoundingBox) {
				iconProps.height = "14px";
				iconProps.viewBox = "0 0 20 14";
			} else {
				iconProps.viewBox = "-2 -5 24 24";
			}
		break;
		case "minus":
			iconProps.svgContent = (
				<g
					fill={props.fillColor}
					fillRule="evenodd"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={props.strokeWidth}
					>
					<path
						d="M1 1.5h14"
						stroke={props.strokeColor}
						vectorEffect={props.vectorEffect}
					/>
				</g>
			);
			
			if(props.noBoundingBox) {
				iconProps.height = "3px";
				iconProps.viewBox = "0 0 16 3";
			} else {
				iconProps.viewBox = "-4 -10.5 24 24";
			}
		break;
		case "minusCircle":
			iconProps.svgContent = (
				<g
					fill={props.fillColor}
					fillRule="evenodd"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={props.strokeWidth}
					transform="translate(1 1)"
					>
					<circle
						cx="10" 
						cy="10" 
						r="10"
						stroke={props.strokeColor}
						vectorEffect={props.vectorEffect}
					/>
					<path
						d="M6 10h8"
						stroke={props.strokeColor}
						vectorEffect={props.vectorEffect}
					/>
				</g>
			);
			
			if(props.noBoundingBox) {
				iconProps.height = "22px";
				iconProps.viewBox = "0 0 22 22";
			} else {
				iconProps.viewBox = "-1 -1 24 24";
			}
		break;
		case "plus":
			iconProps.svgContent = (
				<g
					fill={props.fillColor}
					fillRule="evenodd"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={props.strokeWidth}
					>
					<path
						d="M8 1v14M1 8h14"
						stroke={props.strokeColor}
						vectorEffect={props.vectorEffect}
					/>
				</g>
			);
			
			if(props.noBoundingBox) {
				iconProps.height = "16px";
				iconProps.viewBox = "0 0 16 16";
			} else {
				iconProps.viewBox = "-4 -4 24 24";
			}
		break;
		case "plusCircle":
			iconProps.svgContent = (
				<g
					fill={props.fillColor}
					fillRule="evenodd"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={props.strokeWidth}
					transform="translate(1 1)"
					>
					<circle
						cx="10" 
						cy="10" 
						r="10"
						stroke={props.strokeColor}
						vectorEffect={props.vectorEffect}
					/>
					<path
						d="M10 6v8M6 10h8"
						stroke={props.strokeColor}
						vectorEffect={props.vectorEffect}
					/>
				</g>
			);
			
			if(props.noBoundingBox) {
				iconProps.height = "22px";
				iconProps.viewBox = "0 0 22 22";
			} else {
				iconProps.viewBox = "-1 -1 24 24";
			}
		break;
		case "zap":
			iconProps.svgContent = (
				<g
					fill={props.fillColor}
					fillRule="evenodd"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={props.strokeWidth}
					>
					<path
						d="M11 1 1 13h9l-1 8L19 9h-9z"
						stroke={props.strokeColor}
						vectorEffect={props.vectorEffect}
					/>
				</g>
			);
			
			if(props.noBoundingBox) {
				iconProps.height = "23px";
				iconProps.viewBox = "0 0 20 23";
			} else {
				iconProps.viewBox = "-2 -0.5 24 24";
			}
		break;
	}

	return iconProps;
};

// COMPONENT

const Icon = (props) => {
	const {
		svgContent,
		...svgProps
	} = getIconProps(props);

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			{...svgProps}
			>
			{svgContent}
		</svg>
	);
};

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

// EXPORT

export default Icon;
