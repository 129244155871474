/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import React, {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useReducer,
	useRef,
	useState
} from "react";

import utilsHelper from "../helpers/utils";

import i18n from "../i18n";

// INIT

const formContext = createContext({});

// HELPERS

/**
 * reducer - ...
 * 
 * @param {object} state ...
 * @param {object} action ...
 */
const reducer = (state, action) => {
	switch(action.type) {
		case "RESET_ERRORS":
		return {};
		case "RESET_INPUTS":
		return {};
		case "RESET_STATES":
		return {
			changeFound: false,
			isSubmit: false,
			isSubmitted: false,
			isValid: false
		};
		case "SET":
		return action.payload;
		case "UPDATE":
			console.log(
				2,
				state,
				action.payload
			);
		return {
			...state,
			...action.payload
		};
		case "UPDATE_ERROR":
		return {
			...state,
			[action.payload.name]: {
				...state[action.payload.name],
				error: action.payload.error
			}
		};
		case "UPDATE_VALUE":
		return {
			...state,
			[action.payload.name]: {
				...state[action.payload.name],
				error: action.payload.value
			}
		};
		default:
		return state;
	}
};

/**
 * validateInput - ...
 * 
 * ...
 */
const validateInput = (validators, inputs, inputKey, inputType) => {
	if(utilsHelper.isObjectEmpty(validators)) {
		return;
	}

	if(!inputs.hasOwnProperty(inputKey)) {
		return;
	}

	let error = "";

	const value = inputs[inputKey].value;

	for(const validatorKey of Object.keys(validators)) {
		if(validators[validatorKey] !== false) {
			switch(validatorKey) {
				case "email":
					if(value) {
						error = utilsHelper.validationPatterns[validatorKey].test(value) ? "" : "pattern";
					}
				break;
				case "required":
					if(inputType === "avatarFile") {
						if(!value.file && !value.url) {
							error = validatorKey;
						}
					} else {
						if(!value) {
							error = validatorKey;
						}
					}
				break;
			}
		}

		if(error) {
			break;
		}
	}

	return error;
};

// EXPORT

/**
 * FormProvider - ...
 * 
 * @param {object} props ...
 */
export const FormProvider = ({ children }) => {
	/**
	 * destroyForm - ...
	 */
	const destroyForm = useCallback(() => {
		inputConfigs.current = {};

		dispatchInputErrors({
			type: "RESET_ERRORS"
		});
		dispatchInputs({
			type: "RESET_INPUTS"
		});
		dispatchInputStates({
			type: "RESET_STATES"
		});
	});

	/**
	 * eventHandler - ...
	 * 
	 * @param {object} e ...
	 */
	const eventHandler = useCallback((e) => {
		let newInputPayload = {};

		const {
			target: {
				name,
				value
			},
			type
		} = e;

		if(!inputs[name]) {
			return;
		}

		newInputPayload[name] = Object.assign(
			{}, 
			inputs[name]
		);

		switch(type) {
			case "blur":
				let newConfig = Object.assign(
					{}, 
					inputConfigs.current[name]
				);

				newConfig.touched = true;

				inputConfigs.current[name] = newConfig;
			break;
			case "change":
				newInputPayload[name].value = formatInput(
					name, 
					value
				);
			break;
			case "click":
				switch(inputConfigs.current[name].inputType) {
					default:
						newInputPayload[name].value = value;
					break;
				}
			break;
		}

		dispatchInputs({
			type: "UPDATE",
			payload: newInputPayload
		});
	});

	/**
	 * formatInput - ...
	 * 
	 * @param {string} name ...
	 * @param {mixed} value ... ?
	 */
	const formatInput = useCallback((name, value) => {
		if(!inputConfigs.current[name]) {
			return;
		}

		const formatter = inputConfigs.current[name].formatter;

		switch(formatter) {
			case "lowercase":
			return (
				value ? 
				value.toLowerCase() : 
				value
			);
			default:
			return value;
		}
	});

	/**
	 * getInputErrorText - ...
	 * 
	 * @param {string} inputKey ...
	 */
	const getInputErrorText = useCallback((inputKey) => {
		if(!inputConfigs.current[inputKey]) {
			return;
		}

		console.log(inputErrors[inputKey]);

		return inputErrors[inputKey];
		/*
		return (
			inputErrors[inputKey] ?
			i18n.t(`inputs.error.${inputConfigs.current[inputKey].inputErrorType}.${inputErrors[inputKey]}`) :
			""
		);
		*/
	});	

	/**
	 * hasError - ...
	 * 
	 * @param {string} inputKey ...
	 */
	const hasError = useCallback((inputKey) => {
		return (
			inputErrors[inputKey] ?
			true :
			false
		);
	});	

	/**
	 * initForm - ...
	 * 
	 * @param {object} inputs ...
	 */
	const initForm = useCallback((inputs) => {
		let newInputConfigs = {};
		let newInputErrorPayload = {};
		let newInputPayload = {};

		Object.keys(inputs).map((inputKey) => {
			newInputConfigs[inputKey] = {
				formatter: 
					inputs[inputKey].formatter || 
					""
				,
				initialValue: inputs[inputKey].initialValue,
				inputErrorType: inputs[inputKey].inputErrorType,
				inputType: inputs[inputKey].inputType,
				touched: inputs[inputKey].touched,
				validators: 
					inputs[inputKey].validators || 
					{}
			};

			newInputErrorPayload[inputKey] = "";

			switch(inputs[inputKey].inputType) {
				default:
					newInputPayload[inputKey] = {
						value: inputs[inputKey].initialValue
					};
				break;
			}
		});

		inputConfigs.current = newInputConfigs;

		dispatchInputErrors({
			type: "SET",
			payload: newInputErrorPayload
		});
		dispatchInputs({
			type: "SET",
			payload: newInputPayload
		});
		dispatchInputStates({
			type: "RESET_STATES"
		});
	});

	/**
	 * parseApiErrors - ...
	 * 
	 * @param {object} errors ...
	 */
	const parseApiErrors = useCallback((errors) => {
		errors = isArray(errors) ? errors : [];

		if(!errors.length) {
			return;
		}

		let isValid = true;
		let newInputErrorsPayload = Object.assign({}, inputErrors);

		errors.map((errorItem) => {
			if(newInputErrorsPayload.hasOwnProperty(errorItem.field)) {
				newInputErrorsPayload[errorItem.field] = errorItem.code;

				isValid = false;
			}
		});

		dispatchInputErrors({
			type: "UPDATE",
			payload: newInputErrorsPayload
		});
	});

	/**
	 * setInput - ...
	 * 
	 * @param {string} name ...
	 * @param {object} payload ...
	 */
	const setInput = useCallback((name, payload) => {
		if(!inputs[name]) {
			return;
		}

		let newInputPayload = {};

		newInputPayload[name] = payload;

		dispatchInputs({
			type: "UPDATE",
			payload: newInputPayload
		});
	});

	/**
	 * setInput - ...
	 * 
	 * @param {object} configs ...
	 */
	const setInputConfigs = useCallback((configs) => {
		inputConfigs.current = configs;
	});

	/**
	 * setInputError - ...
	 * 
	 * @param {string} name ...
	 * @param {mixed} value ...
	 */
	const setInputError = useCallback((name, value) => {
		console.log(name, value);
		if(!inputErrors[name]) {
			return;
		}
		console.log(name, value);
		let newInputErrorPayload = {};

		newInputErrorPayload[name] = value;

		dispatchInputErrors({
			type: "UPDATE",
			payload: newInputErrorPayload
		});
	});

	/**
	 * setInputStates - ...
	 * 
	 * @param {object} payload ...
	 */
	const setInputStates = useCallback((payload) => {
		dispatchInputStates({
			type: "UPDATE",
			payload: payload
		});
	});

	/**
	 * validateForm - ...
	 * 
	 * @param {boolean} checkTouched ...
	 */	
	const validateForm = useCallback((checkTouched = false) => {
		let changeFound = false;
		let isValid = true;
		let newInputErrorsPayload = Object.assign(
			{}, 
			inputErrors
		);

		Object.keys(inputConfigs.current).map((inputKey) => {
			const validators = 
				inputConfigs.current[inputKey].validators || 
				{}
			;

			if(!utilsHelper.isObjectEmpty(validators)) {
				const error = validateInput(
					validators,
					inputs,
					inputKey,
					inputConfigs.current[inputKey].inputType 
				);

				if(error) {
					isValid = false;
				}

				console.log(
					inputKey,
					error
				);

				if(
					inputConfigs.current[inputKey].touched || 
					!checkTouched
				) {
					newInputErrorsPayload[inputKey] = error;
				}
			}

			if(!changeFound) {
				switch(inputConfigs.current[inputKey].inputType) {
					default:
						if(
							inputs[inputKey].value !== 
							inputConfigs.current[inputKey].initialValue
						) {
							changeFound = true;
						}
					break;
				}
			}
		});

		console.log(1, newInputErrorsPayload);

		dispatchInputErrors({
			type: "UPDATE",
			payload: newInputErrorsPayload
		});
		dispatchInputStates({
			type: "UPDATE",
			payload: {
				changeFound: changeFound,
				isValid: isValid
			}
		});
	});

	const [
		inputErrors, 
		dispatchInputErrors
	] = useReducer(
		reducer, 
		{}
	);
	const [
		inputs, 
		dispatchInputs
	] = useReducer(
		reducer, 
		{}
	);
	const [
		inputStates, 
		dispatchInputStates
	] = useReducer(
		reducer, 
		{}
	);

	const inputConfigs = useRef({});

	// Lifecycle

	// Note - Run on bootstrap
	useEffect(
		() => {
			if(utilsHelper.isObjectEmpty(inputs)) {
				return;
			}

			validateForm(true);
		}, 
		[inputs]
	);

	// Render

	return (
		<formContext.Provider
			value={{
				destroyForm,
				eventHandler,
				formatInput,
				getInputErrorText,
				hasError,
				initForm,
				inputConfigs,
				inputErrors,
				inputs,
				inputStates,
				parseApiErrors,
				setInput,
				setInputConfigs,
				setInputError,
				setInputStates,
				validateForm
			}}
			>
			{children}
		</formContext.Provider>
	);
};

/**
 * useForm - ...
 */
export const useForm = () => {
	const {
		destroyForm,
		eventHandler,
		formatInput,
		getInputErrorText,
		hasError,
		initForm,
		inputConfigs,
		inputErrors,
		inputs,
		inputStates,
		parseApiErrors,
		setInput,
		setInputConfigs,
		setInputError,
		setInputStates,
		validateForm
	} = useContext(formContext);

	return {
		destroyForm,
		eventHandler,
		formatInput,
		getInputErrorText,
		hasError,
		initForm,
		inputConfigs,
		inputErrors,
		inputs,
		inputStates,
		parseApiErrors,
		setInput,
		setInputConfigs,
		setInputError,
		setInputStates,
		validateForm
	};
};

export default {
	FormProvider,
	useForm
};

