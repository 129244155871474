/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import routes from "./configs/routes";

import Account from "./screens/Account";
import Activate from "./screens/Activate";
import ActivateSuccess from "./screens/ActivateSuccess";
import Download from "./screens/Download";
import Faq from "./screens/Faq";
import Home from "./screens/Home";
import Login from "./screens/Login";
import NotFound from "./screens/NotFound";
import QrCode from "./screens/QrCode";
import Welcome from "./screens/Welcome";

// INIT

let router = [];

const components = {
	Account,
	Activate,
	ActivateSuccess,
	Download,
	Faq,
	Home,
	Login,
	NotFound,
	QrCode,
	Welcome
};

// ROUTER

Object.keys(routes).map((routeKey, routeIndex) => {
	const routeItem = routes[routeKey];

	router[routeIndex] = {
		component: components[routeItem.componentName],
		path: `/${routeItem.url}`
	};
});

router.push({
	component: NotFound,
	path: "*"
});

// EXPORT

export default router;
