import axios from "axios";

import apiHelper from "../helpers/api";

import {
	isFunction,
	isObject
} from "../helpers/utils";

// INIT

axios.interceptors.request.use(
	async (config) => {
		config.headers["X-Api-Key"] = apiHelper.getApiKey();

		if(
			!config.url.startsWith("/auth") &&
			!config.url.startsWith("/oauth/request")
		) {
			//config.headers["Authorization"] = apiHelper.getAuthToken();
		}

		return config;
	},
	async (error) => {
		return Promise.reject(error);
	}
);
/*
axios.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const errorPayload = 
			error.response.data || 
			{}
		;

		if(!errorPayload.code) {
			// ??? why? Is this was related to old api?
			error.response.data = {
				code: "unexpectedError",
				message: httpStatuses[error.response.status],
				type: "ResponseError"
			};
		} else {
			const originalRequest = error.config;

			if(
				error.response.status === 401 && 
				errorPayload.code === "authTokenExpired" &&
				!originalRequest._retry
			) {			
		  		originalRequest._retry = true;

		  		const refreshRequest = await axios({
					data: {
						refreshToken: apiHelper.getRefreshToken()
					},
					method: "post",
					url: `${apiHelper.getApiUrl()}/auth/refresh-token`
				});           
	  
				const payload = refreshRequest.data || {};

				// what if error here ???
				if(payload.authToken) {
					apiHelper.setAuthHeader(payload.authToken);

					originalRequest.headers["Authorization"] = axios.defaults.headers.common["Authorization"];
					// also need to save to storeHelper

					//reduxHelper.dispatch( 
					//	"SET_USER_AUTH_TOKEN", 
					//	response.authToken
					//); 
				}

				console.log(axios.defaults.headers.common);
				console.log(originalRequest);
			
				return axios(originalRequest);
			}
		}

		return Promise.reject(error);
	}
);
*/
// EXPORT

/**
 * apiRequest - ...
 */
export const apiRequest = (options) => {
	options = 
		options || 
		{}
	;

	let requestResponse = {};

	return axios({
		baseURL: apiHelper.getApiUrl(),
		...options
	}).then((response) => {
		requestResponse = response.data;
	}).catch((error) => {
		requestResponse.error = apiHelper.parseCatchError(error);
	}).then((response) => {
		return requestResponse;
	});
};
