/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import PropTypes from "prop-types";
import React from "react";

import {
	ComponentBodyWrapper,
	ComponentHeaderWrapper,
	ComponentWrapper,
	IconWrapper,
	LogoWrapper
} from "./Sidebar.styled";

import Icon from "../ui/Icon";
import Logo from "../ui/Logo";

// INIT

const propTypes = {
	isUserConnected: PropTypes.bool
};
const defaultProps = {
	isUserConnected: false
};

// COMPONENT

const Sidebar = (props) => {
	// Render

	/**
	 * render - ...
	 */
	const render = () => {
		if(props.isUserConnected) {
		//if(!props.isUserConnected) {
			return null;
		}

		return (
			<ComponentWrapper>
				<ComponentHeaderWrapper>
					<LogoWrapper
						onClick={() => { console.log("click logo"); }}
						>
						<Logo
							name="dataxtract"
						/>
					</LogoWrapper>
				</ComponentHeaderWrapper>
				<ComponentBodyWrapper>
					<div
						style={{
							display: "flex",
							flex: 1,
							flexDirection: "column",
							gap: "12px"
						}}
						>
						<div
							style={{
								alignItems: "center",
								display: "flex",
								gap: "12px"
							}}
							>
							<IconWrapper>
								<Icon
									name="menu"
									noBoundingBox
								/>
							</IconWrapper>
							<span
								style={{
									lineHeight: "24px"
								}}
								>
								Dashboard
							</span>
						</div>

						<div
							style={{
								alignItems: "center",
								display: "flex",
								gap: "12px"
							}}
							>
							<IconWrapper>
								<Icon
									name="menu"
									noBoundingBox
								/>
							</IconWrapper>
							<span
								style={{
									lineHeight: "24px"
								}}
								>
								Extract
							</span>
						</div>

						<div
							style={{
								alignItems: "center",
								display: "flex",
								gap: "12px"
							}}
							>
							<IconWrapper>
								<Icon
									name="menu"
									noBoundingBox
								/>
							</IconWrapper>
							<span
								style={{
									lineHeight: "24px"
								}}
								>
								History
							</span>
						</div>
					</div>
					<div>
						<div>
							Upgrade box
						</div>
					</div>
				</ComponentBodyWrapper>
			</ComponentWrapper>
		);  
	};

	return render();
};

Sidebar.propTypes = propTypes;
Sidebar.defaultProps = defaultProps;

// EXPORT

export default Sidebar;
