/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import {
	initReactI18next
} from "react-i18next";

// INIT

i18n
	.use(initReactI18next)
	.use(HttpApi)
	.init(
		{
			backend: {
				loadPath: "/assets/locales/en.json"
			},
			debug: false, //coreConfig.debug,
			fallbackLng: "en",
			interpolation: {
				escapeValue: false
			},
			load: "languageOnly" //?
		},
		(error) => {
			if(error) {
				console.log("i18next error", error);
			}
		}
	)
;

// EXPORT

export default i18n;
