/*
TODO

- remove generateSecondarHexColor & polished once secondary color set in webpack
*/

import {
	darken,
	lighten,
	transparentize
} from "polished";

import {
	colors
} from "./styled-themes";

export const jsonStyleToString = (json) => {
	if(Object.keys(json).length === 0) {
		return "";
	}

	return Object.entries(json).map(([k, v]) => {
		return `${k}:${v}`;
	}).join(";");
};

export const generateSecondaryHexColor = (hex, percentageVariant) => {
	percentageVariant = percentageVariant || 0.3;

	let rgb, r, g, b, hsp;
	/*
	// Check the format of the color, HEX or RGB?
	if (color.match(/^rgb/)) {
	
			// If RGB --> store the red, green, blue values in separate variables
			color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
			
			r = color[1];
			g = color[2];
			b = color[3];
		} 
		else {
	*/
	rgb = +("0x" + hex.slice(1).replace(hex.length < 5 && /./g, "$&$&"));
	
	r = rgb >> 16;
	g = rgb >> 8 & 255;
	b = rgb & 255;

	hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
	
	if(hsp > 127.5) {
		return darken(percentageVariant, hex);
	} else {
		return lighten(percentageVariant, hex);
	}
};

export const setFont = (family, size, weight, height, color, style = false) => {
	let fontStyle = "";

	if(style) {
		fontStyle = `font-style: ${style};`;
	}

	return `
		font-family: ${family};
		font-size: ${size};
		font-weight: ${weight};
		line-height: ${height};
		color: ${color};
		${fontStyle}
	`;
};

export const setPosition = (position, options = {}) => {
	let positionOptions = [];

	for(let key in options) {
		positionOptions.push(`${key}: ${options[key]};`);
	}

	return `
		position: ${position};
		${positionOptions.join("")}
	`;
};

export const setScrollShadow = (direction) => {
	let clip = "unset";
	let positionOptions = {};

	if(direction === "top") {
		clip = `rect(
			-32px,
			auto,
			0,
			0
		)`;
		positionOptions.top = 0;
	} else {
		clip = `rect(
			32px,
			auto,
			64px,
			0
		)`;
		positionOptions.bottom = 0;
	}

	// valuidate that 32px will work also on desktop
	return `
		height: 32px;
		border-radius: 50%;
		box-shadow: 0 0 16px 0 ${transparentize(
			0.9,
			colors.black
		)};
		clip: ${clip};
		content: "";
		transition: opacity 200ms;
		will-change: opacity;
		${setPosition(
			"absolute", {
				"left": 0,
				"right": 0,
				"z-index": 1,
				...positionOptions
			}
		)}
	`;
};