/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import reduxStore from "./configure";

// INIT

const {
	persistor,
	store
} = reduxStore();

// EXPORT

export {
	persistor,
	store
};
