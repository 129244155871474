/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import styled from "styled-components";

import {
	columnWrapper,
	rowWrapper
} from "../../styled/styled-extends";

// EXPORT

export const DownloadLinkWrapper = styled.small`
	gap: 48px;
	${rowWrapper}

	a {
		text-decoration: underline;
	}
`;

export const DownloadWrapper = styled.div`
	align-items: center;
	gap: 16px;
	margin-bottom: 24px;
	text-align: center;
	width: 100%;
	${columnWrapper}

	> small {
		font-size: 12px;
	}
`;

export const GraphicWrapper = styled.div`
	align-items: center;
	margin-bottom: 48px;
	height: 140px;
	${columnWrapper}

	svg {
		height: 100%;
	}
`;

export const LogoWrapper = styled.div`
	align-items: center;
	max-width: 160px;
	width: 100%;
	${columnWrapper}

	svg {
		height: 100%;
		width: 100%;
	}
`;

export const ScreenBodyWrapper = styled.div`
	align-items: center;
	gap: 24px;
	max-width: 480px;
	width: 100%;
	${columnWrapper}
`;

export const ScreenFooterWrapper = styled.div`
	align-items: center;
	text-align: center;
	width: 100%;
	${columnWrapper}
`;

export const ScreenHeaderWrapper = styled.div`
	align-items: center;
	text-align: center;
	width: 100%;
	${columnWrapper}

	p {
		color: #FFF;
		max-width: 480px;
		padding-top: 0;
	}
`;

export const ScreenInnerWrapper = styled.div`
	align-items: center;
	gap: 48px;
	width: 100%;
	${columnWrapper}
`;

export const ScreenStepWrapper = styled.div`
	align-items: center;
	gap: 16px;
	text-align: center;
	width: 100%;
	${columnWrapper}
`;

export const StepWrapper = styled.small`
	background-color: #FFC5FF;
	border-radius: 999px;
	color: #000;
	font-family: "Roc Grotesk";
	font-weight: 500;
	line-height: 1.25;
	padding: 6px 24px 4px 24px;
`;

export const ScreenWrapper = styled.div`
	align-items: center;
	flex: 1;
	justify-content: center;
	padding: 24px 32px;
	text-align: center;
	width: 100%;
	${columnWrapper}
	
	@media only screen and (min-width: 768px) {
		padding: 96px 32px;

		${GraphicWrapper} {
			margin-bottom: 64px;
			height: 240px;
		}

		${LogoWrapper} {
			max-width: 240px;
		}

		${ScreenInnerWrapper} {
			gap: 64px;
		}

		${ScreenStepWrapper} {
			gap: 24px;
		}
	}
`;
