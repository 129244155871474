/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import styled from "styled-components";

import {
	disabled,
	fill,
	loaderWrapper,
	rowWrapper
 } from "../../../styled/styled-extends";
import { 
	setFont,
	setPosition
} from "../../../styled/styled-mixins";
import {
	fonts
} from "../../../styled/styled-variables";

// EXPORT

export const ButtonComponent = styled.div`
	${disabled}
	align-items: center;
	box-sizing: border-box;
	display: flex;
	outline: none;
	text-align: center;
	transition: background-color 200ms, color 200ms;
	${setPosition("relative")}

	${(props) => {
		const {
			button,
			typo
		} = props.theme;

		let buttonBg = "#1B1516";
		let buttonCursor = "pointer";
		let buttonFg = props.foregroundColor;
		let buttonHeight;
		let contentOpacity = 1;
		let contentFontSize;
		let contentHeight;
		let contentHorizontalPadding;
		let contentLineHeight;
		let contentVerticalPadding;
		let loaderOpacity = 0;

		if(props.disabled) {
			buttonBg = "#CCC";
			buttonCursor = "not-allowed";
			//buttonFg = paletteColors.disabled.foreground;
		}

		if(props.loaderEnable && props.loaderActive) {
			buttonCursor = "not-allowed";
			contentOpacity = 0;
			loaderOpacity = 1;
			// add pointer-events
		}

		switch(props.size) {
			case "large":
				buttonHeight = "54px";
				contentFontSize = "19px";
				contentHorizontalPadding = "12px";
				contentLineHeight = "24px";
			break;
			case "small":
				buttonHeight = "40px";
				contentFontSize = "15px";
				contentHorizontalPadding = "8px";
				contentLineHeight = "20px";
			break;
			case "xlarge":
				buttonHeight = "60px";
				contentFontSize = "21px";
				contentHorizontalPadding = "14px";
				contentLineHeight = "26px";
			break;
			case "xsmall":
				buttonHeight = "32px";
				contentFontSize = "13px";
				contentHorizontalPadding = "6px";
				contentLineHeight = "18px";
			break;
			default:
				buttonHeight = "48px";
				contentFontSize = "17px";
				contentHorizontalPadding = "10px";
				contentLineHeight = "22px";
			break;
		}

		return `
			background-color: ${buttonBg};
			border-radius: 7px;
			cursor: ${buttonCursor};
			height: ${buttonHeight};

			${LoaderWrapper} {
				opacity: ${loaderOpacity};

				.buttonLoader {
					border-top-color: ${buttonFg};
					height: ${contentLineHeight};
					width: ${contentLineHeight};
				}
			}

			${RowWrapper} {
				opacity: ${contentOpacity};
				padding: 0 ${contentHorizontalPadding};
				${setFont(
					fonts.inter,
					contentFontSize,
					700,
					"normal",
					buttonFg
				)}

				> * {
					padding: 0 ${contentHorizontalPadding};

					:not(:first-child) {
						margin-left: -${contentHorizontalPadding};
					}
				}
			}
		`;
	}};
`;

export const InnerWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const LabelWrapper = styled.div`
	${rowWrapper}
	align-items: center;
	flex: 1;
	justify-content: center;
`;

export const LoaderWrapper = styled.div`
	${loaderWrapper}
	pointer-events: none;
`;

export const RowWrapper = styled.div`
	${rowWrapper}
	flex-shrink: unset; // [NOTE] Important to keep
	transition: opacity 200ms;
	width: 100%;
	will-change: opacity;

	svg {
		circle,
		path,
		rect {
			stroke: currentColor;
		}
	}
`;
