/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import styled from "styled-components";

import {
	rowWrapper
 } from "../../../styled/styled-extends";
import {
	setFont
} from "../../../styled/styled-mixins";
import {
	colors,
	fonts
} from "../../../styled/styled-variables";

// EXPORT

export const ComponentWrapper = styled.div`
	align-items: stretch;
	background-color: #252527;
	border-radius: 8px;
	height: 48px;
	text-align: left;
	width: 100%;
	${rowWrapper}
	${setFont(
		"Inter",
		"16px",
		400,
		1.5,
		"#FFF"
	)}

	input {
		appearance: none;
		background-color: transparent;
		border: 0;
		box-sizing: border-box;
		color: inherit;
		font: inherit;
		height: 100%;
		margin: 0;
		outline: none;
		padding: 12px 16px;
		text-align: inherit;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;
	}
`;
