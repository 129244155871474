import {
	store
} from "../store";

/**
 * dispatch - Dispatch a Redux action.
 * 
 * @param {string} type Action type
 * @param {mixed} payload Action payload
 */
const dispatch = (type, payload) => {
	if(!type) {
		return;
	}

	store.dispatch({
		type,
		payload
	});
};

/**
 * getState - Get Redux store state.
 */
const getState = () => {
	return store.getState();
};

// EXPORT

export default {
	dispatch,
	getState
};
