/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import styled from "styled-components";

import {
	columnWrapper
} from "../../../styled/styled-extends";
import { 
	setPosition
} from "../../../styled/styled-mixins";
import {
	colors
} from "../../../styled/styled-variables";

// EXPORT

export const ComponentWrapper = styled.div`
	width: 100%;
	${columnWrapper}
	
	@media only screen and (min-width: 768px) {
	}

	@media only screen and (min-width: 1024px) {
	}

	@media only screen and (min-width: 1248px) {
	}
`;
