/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import PropTypes from "prop-types";
import React, {
	useCallback,
	useEffect,
	useRef,
	useState
} from "react";

import {
	ComponentWrapper
} from "./InputText.styled";

// INIT

const propTypes = {
	append: PropTypes.node,
	autoComplete: PropTypes.string,
	autoCapitalize: PropTypes.string,
	autoFocus: PropTypes.bool,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	hasError: PropTypes.bool,
	id: PropTypes.string,
	inputMode: PropTypes.oneOf([
		"decimal",
		"numeric", 
		"search", 
		"url"
	]),
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	pattern: PropTypes.string,
	placeholder: PropTypes.string,
	prepend: PropTypes.node,
	readOnly: PropTypes.bool,
	spellCheck: PropTypes.bool,
	style: PropTypes.object,
	type: PropTypes.oneOf([
		"datetime-local",
		"email",
		"hidden",
		"text"
	]),
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	wrapperClassName: PropTypes.string,
	wrapperStyle: PropTypes.object
};
const defaultProps = {
	autoComplete: "new-password", // Note - "off" not working on Chrome
	autoCapitalize: "off",
	//autoFocus: false,
	disabled: false,
	hasError: false,
	readOnly: false,
	//spellCheck: false,
	type: "text"
};

// COMPONENT

const InputText = (props) => {
	/**
	 * actionHandler - ...
	 * 
	 * @param {object} e ...
	 */
	const actionHandler = useCallback((e) => {
		e.preventDefault();
		e.stopPropagation();

		if(
			props.disabled ||
			props.readOnly
		) {
			return;
		}

		switch(e.type) {
			case "blur":
				if(clickOutside.current) {
					setInputFocus(false);
				}

				if(props.onBlur) {
					props.onBlur(e);
				}
			break;
			case "change":
				if(props.onChange) {
					props.onChange(e);
				}
			break;
			case "focus":
				setInputFocus(true);

				if(props.onFocus) {
					props.onFocus(e);
				}
			break;
		}
	});

	/**
	 * clickOutsideHandler - ...
	 * 
	 * @param {object} e ...
	 */
	const clickOutsideHandler = useCallback((e) => {
        if(
			componentRef && 
			!componentRef.current.contains(e.target)
		) {
			clickOutside.current = true;
        } else {
			clickOutside.current = false;
		}
    });

	const clickOutside = useRef(false);
	const componentRef = useRef(null);
	const inputRef = useRef(null);
	
	const [
		inputFocus, 
		setInputFocus
	] = useState(false);

	// Lifecycle

	// Note - Run on bootstrap
	useEffect(
		() => {
			document.addEventListener(
				"mousedown", 
				clickOutsideHandler
			);

			return () => {
				document.removeEventListener(
					"mousedown", 
					clickOutsideHandler
				);
			};
    	}, 
		[]
	);

	// Render

	/**
	 * render - ...
	 */
	const render = () => {
		let componentWrapperProps = {
			disabled: props.disabled,
			hasFocus: inputFocus,
			readOnly: props.readOnly,
			ref: componentRef
		};

		const {
			append,
			hasError,
			onBlur,
			onChange,
			onFocus,
			prepend,
			wrapperClassName,
			wrapperStyle,
			...inputProps
		} = props;

		componentWrapperProps.hasError = hasError;

		if(wrapperClassName) {
			componentWrapperProps.className = wrapperClassName;
		}

		if(wrapperStyle) {
			componentWrapperProps.style = wrapperStyle;
		}

		return (
			<ComponentWrapper
				{...componentWrapperProps}
				>
				{prepend}
				<input
					onBlur={actionHandler}
					onChange={actionHandler}
					onFocus={actionHandler}
					ref={inputRef}
					{...inputProps}
				/>
				{append}
			</ComponentWrapper>
		);
	};

	return render();
};

InputText.propTypes = propTypes;
InputText.defaultProps = defaultProps;

// EXPORT

export default InputText;
