/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import React, {
	useEffect,
	useState
} from "react";
import {
	connect
} from "react-redux";
import {
	useNavigate,
	useSearchParams
} from "react-router-dom";

import {
	LogoWrapper,
	ScreenBodyWrapper,
	ScreenFooterWrapper,
	ScreenHeaderWrapper,
	ScreenInnerWrapper,
	ScreenWrapper
} from "./Home.styled";

import InputWrapper from "../../components/InputWrapper";

import Button from "../../components/ui/Button";
import InputText from "../../components/ui/InputText";
import Logo from "../../components/ui/Logo";

import {
	useForm
} from "../../contexts/form";

import storeHelper from "../../helpers/store";
import utilsHelper from "../../helpers/utils";

import useApi from "../../hooks/api";

import i18n from "../../i18n";

// INIT

/**
 * mapStateToProps - ...
 * 
 * @param {object} state ...
 */
const mapStateToProps = (state) => {
	return {
		...state
	};
};

// COMPONENT

const Home = (props) => {
	const [
		errorCode, 
		setErrorCode
	] = useState("");
	const [
		errorEmail, 
		setErrorEmail
	] = useState("");
	const [
		isRenderReady, 
		setIsRenderReady
	] = useState(false);
	const [
		qrCode,
		setQrCode
	] = useState(null);
	const [
		qs, 
		setQs
	] = useState(null);

	const {
		fetchApi
	} = useApi();
	const {
		destroyForm,
		eventHandler,
		initForm,
		inputs,
		inputErrors,
		inputStates,
		setInputError,
		setInputStates,
		validateForm
	} = useForm();
	const navigate = useNavigate();
	const [
		searchParams
	] = useSearchParams();

	// Lifecycle

	// Note - Run on bootstrap
	useEffect(
		() => {
			/*
			const registerResponse = fetchApi({
				data: {
					email: "user03@gmail.com",
					firstName: "user03",
					isPurchases: false,
					lastName: "user03",
					productId: 8200488911104,
					productInfo: {
						productImage: "https://cdn.shopify.com/s/files/1/0629/8202/9568/files/shoe.jpg?v=1684910523&_accept=image/avif",
						variant: "Men's 10",
						name: "Travis Scott x Air Jordan 1 Olive"
					},
					registrationComplete: false,
					ticket: "user03",
					variantId: 43761242865920
				},
				method: "post",
				url: "/register"
			});
			*/
			setQs(Object.fromEntries([...searchParams]));

			return () => {
				destroyForm();
			};
		}, 
		[]
	);

	// Note - Run when qs change
	useEffect(
		() => {
			if(utilsHelper.isObject(qs)) {
				initInputs();
			}
		}, 
		[qs]
	);

	// Note - Run when input change
	useEffect(
		() => {
			if(utilsHelper.isObjectEmpty(inputs)) {
				return;
			}

			if(!isRenderReady) {
				setIsRenderReady(true);
			}
		}, 
		[inputs]
	);

	// Note - Run when qrCode change
	useEffect(
		() => {
			if(
				!qrCode ||
				!props.LOCAL.user.email
			) {
				return;
			}
	
			navigate(
				{
					pathname: "/welcome"
				}, {
					state: {
						qrcode: qrCode
					}
				}
			);
		}, 
		[qrCode]
	);

	// Note - Run when inputErrors change
	useEffect(
		() => {
			if(utilsHelper.isObjectEmpty(inputErrors)) {
				return;
			}

			if(inputErrors.confirmationNumber) {
				setErrorCode("Invalid entry. Please try again.");
			} else {
				setErrorCode("");
			}

			if(inputErrors.email) {
				setErrorEmail("Invalid email. Please try again.");
			} else {
				setErrorEmail("");
			}
		}, 
		[inputErrors]
	);

	// Events

	/**
	 * goToLogin - ...
	 * 
	 * @param {object} e ...
	 */
	const goToLogin = (e) => {
		navigate({
			pathname: "/login",
			search: `?email=${inputs.email.value}`
		});
	};

	/**
	 * submitFormHandler - ...
	 * 
	 * @param {object} e ...
	 */
	const submitFormHandler = async (e) => {
		if(
			!inputStates.isValid || 
			inputStates.isSubmit
		) {
			validateForm(false);

			return;
		}

		setInputStates({
			isSubmit: true
		});

		const qrcodeResponse = await fetchApi({
			params: {
				email: inputs.email.value,
				ticket: inputs.confirmationNumber.value
			},
			method: "get",
			url: "/get-2fa-qr"
		});

		if(!qrcodeResponse.ok) {
			if(qrcodeResponse.message === "User not found.") {
				setErrorCode("");
				setErrorEmail("Invalid email. Please try again.");
			} else if(qrcodeResponse.message === "ticket not found.") {
				setErrorCode("Invalid entry. Please try again.");
				setErrorEmail("");
			} else {
				if(qrcodeResponse.error?.message === "user already signed up...") {
					setErrorCode("");
					setErrorEmail("Account already registered. Please login.");
				}
			}
		} else {
			storeHelper.dispatch(
				"SET_USER_EMAIL", 
				inputs.email.value
			);
			storeHelper.dispatch(
				"SET_USER_FIRST_NAME", 
				qrcodeResponse.firstName
			);
			storeHelper.dispatch(
				"SET_USER_LAST_NAME", 
				qrcodeResponse.lastName
			);

			setQrCode({
				image: qrcodeResponse.imageData,
				secret: qrcodeResponse.secret
			});
		}

		setInputStates({
			isSubmit: false
		});
	};

	// Misc
	
	/**
	 * initInputs - ...
	 */
	const initInputs = () => {
		const newForm = {
			confirmationNumber: {
				formatter: "",
				initialValue: 
					qs.confirmation_number ||
					""
				,
				inputErrorType: "text",
				inputType: "text",
				touched: false,
				validators: {
					required: true
				}
			},
			email: {
				formatter: "lowercase",
				initialValue: 
					qs.email ||
					""
				,
				inputErrorType: "email",
				inputType: "text",
				touched: false,
				validators: {
					email: true,
					required: true
				}
			}
		};

		initForm(newForm);
	};

	// Render

	/**
	 * render - ...
	 */
	const render = () => {
		let content;

		if(
			isRenderReady && 
			!utilsHelper.isObjectEmpty(inputs)
		) {
			content = (
				<ScreenInnerWrapper>
					<ScreenHeaderWrapper>
						<LogoWrapper>
							<Logo
								name="copdateInsider"
							/>
						</LogoWrapper>
						<h2>
							{i18n.t("screens.home.header.title")}
						</h2>
						<p>
							{i18n.t("screens.home.header.text")}
						</p>
					</ScreenHeaderWrapper>
					<ScreenBodyWrapper>
						<InputWrapper
							errorText={errorEmail}
							label={i18n.t("inputs.label.email")}
							>
							<InputText
								name="email"
								onBlur={eventHandler}
								onChange={eventHandler}
								placeholder={i18n.t("inputs.placeholder.email")}
								type="email"
								{...inputs.email}
							/>
						</InputWrapper>
						<InputWrapper
							errorText={errorCode}
							label={i18n.t("inputs.label.confirmationNumber")}
							>
							<InputText
								name="confirmationNumber"
								onBlur={eventHandler}
								onChange={eventHandler}
								placeholder={i18n.t("inputs.placeholder.confirmationNumber")}
								{...inputs.confirmationNumber}
							/>
						</InputWrapper>
						<Button
							label={i18n.t("actions.continue")}
							loaderActive={inputStates.isSubmit}
							loaderEnable
							onClick={submitFormHandler}
						/>
					</ScreenBodyWrapper>
					<ScreenFooterWrapper>
						<small>
							{i18n.t("screens.home.footer.alreadyRegistered")} 
							&nbsp;
							<a
								href=""
								onClick={goToLogin}
								>
								{i18n.t("actions.login")}
							</a>
						</small>
					</ScreenFooterWrapper>
				</ScreenInnerWrapper>
			);
		}

		return (
			<ScreenWrapper>
				{content}
			</ScreenWrapper>
		);
	};

	return render();
};

// EXPORT

export default connect(mapStateToProps)(Home);
