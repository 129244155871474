/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import PropTypes from "prop-types";
import React from "react";

import {
	ButtonComponent,
	InnerWrapper,
	LabelWrapper,
	LoaderWrapper,
	RowWrapper
} from "./Button.styled";

import LoaderAnimation from "../LoaderAnimation";

import {
	colors
} from "../../../styled/styled-variables";

// INIT

const propTypes = {
	append: PropTypes.any,
	appendStyle: PropTypes.object,
	backgroundColor: PropTypes.string, 
	className: PropTypes.string,
	disabled: PropTypes.bool,
	foregroundColor: PropTypes.string,
	label: PropTypes.node.isRequired,
	labelStyle: PropTypes.object,
	loaderActive: PropTypes.bool,
	loaderEnable: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	prepend: PropTypes.any,
	prependStyle: PropTypes.object,
	size: PropTypes.oneOf([
		"default",
		"large",
		"small",
		"xlarge",
		"xsmall"
	]),
	style: PropTypes.object
};
const defaultProps = {
	appendStyle: {},
	backgroundColor: colors.black, 
	className: "",
	disabled: false,
	foregroundColor: colors.white, 
	labelStyle: {},
	loaderActive: false,
	loaderEnable: false,
	prependStyle: {},
	size: "default",
	style: {}
};

// COMPONENT

const Button = (props) => {
	// Render

	const render = () => {
		const {
			append,
			appendStyle,
			label,
			labelStyle,
			prepend,
			prependStyle,
			...buttonProps
		} = props;

		let appendContent;
		let loaderContent;
		let prependContent;

		if(append) {
			appendContent = (<InnerWrapper
				style={appendStyle}
			>
				{append}
			</InnerWrapper>);
		}

		if(props.loaderEnable) {
			loaderContent = (<LoaderWrapper>
				<LoaderAnimation
					className="buttonLoader"
				/>
			</LoaderWrapper>);
		}

		if(prepend) {
			prependContent = (<InnerWrapper
				style={prependStyle}
			>
				{prepend}
			</InnerWrapper>);
		}

		return (
			<ButtonComponent
				data-component="button"
				{...buttonProps}
				>
				{loaderContent}
				<RowWrapper>
					{prependContent}
					<LabelWrapper
						className="buttonLabel"
						>
						{label}
					</LabelWrapper>
					{appendContent}
				</RowWrapper>
			</ButtonComponent>
		);
	};

	return render();
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

// EXPORT

export default Button;
