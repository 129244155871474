/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import styled from "styled-components";

import {
	columnWrapper,
	rowWrapper
} from "../../styled/styled-extends";
import { 
	setPosition
} from "../../styled/styled-mixins";
import {
	colors,
	fonts
} from "../../styled/styled-variables";

// EXPORT

export const ComponentBodyWrapper = styled.div`
	flex: 1;
	padding: 24px;
	${columnWrapper}
`;

export const ComponentHeaderWrapper = styled.div`
	align-items: center;
	padding: 24px 16px;
	${columnWrapper}
`;

export const IconWrapper = styled.div`
	cursor: pointer;

	svg {
		circle,
		path,
		rect {
			stroke: #999;//input.border.colors.focus;
		}
	}
`;

export const LogoWrapper = styled.div`
	cursor: pointer;

	svg {
		height: 20px;
	}
`;

export const ComponentWrapper = styled.div`
	background-color: ${colors.white};
	border-right: 1px solid #EAECF0;
	//padding: 16px;
	width: 320px;
	${columnWrapper}
	
	@media only screen and (min-width: 768px) {
	}

	@media only screen and (min-width: 1024px) {
	}

	@media only screen and (min-width: 1248px) {
	}
`;
