/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import {
	combineReducers,
	createStore
} from "redux";
import {
	createMigrate,
	persistReducer,
	persistStore
} from "redux-persist";
import localStorage from "redux-persist/lib/storage";
import sessionStorage from "redux-persist/lib/storage/session";
import expireReducer from "redux-persist-expire";

import {
	localReducer,
	sessionReducer
} from "./reducers";

// INIT

const localMigrations = {
	0: (state) => {
		return {
			...state
	  	}
	}
};
const localPersistConfig = {
	key: "development", //... pass ENV
	migrate: createMigrate(
		localMigrations, 
		{
			debug: false
		}
	),
	storage: localStorage,
	transforms: [],
	version: 0,
	blacklist: []
};
const localPersistReducer = persistReducer(
	localPersistConfig, 
	localReducer
);

const sessionMigrations = {
	0: (state) => {
		return {
			...state
	  	}
	}
};
const sessionPersistConfig = {
	key: "development", //... pass ENV
	migrate: createMigrate(
		sessionMigrations, 
		{
			debug: false
		}
	),
	storage: sessionStorage,
	transforms: [
		expireReducer(
			"oauthApp", 
			{
				autoExpire: true,
		  		expireSeconds: 60,
		  		expiredState: {
					appIconUrl: "",
					appUrl: "",
					clientId: "",
					description: "",
					name: ""
				}
			}
		)
	],
	version: 0,
	blacklist: []
};
const sessionPersistReducer = persistReducer(
	sessionPersistConfig, 
	sessionReducer
);

const persistedReducers = combineReducers({
	LOCAL: localPersistReducer,
	SESSION: sessionPersistReducer
});

const store = createStore(persistedReducers);
const persistor = persistStore(store);

// EXPORT

export default () => {
	return {
		store,
		persistor
	};
};
