/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import React, {
	useEffect,
	useState
} from "react";
import {
	connect
} from "react-redux";
import {
	useNavigate,
	useSearchParams
} from "react-router-dom";

import {
	GraphicWrapper,
	LogoWrapper,
	ScreenBodyWrapper,
	ScreenFooterWrapper,
	ScreenHeaderWrapper,
	ScreenInnerWrapper,
	ScreenStepWrapper,
	ScreenWrapper,
	SetupCodeLinkWrapper,
	SetupCodeWrapper,
	StepWrapper
} from "./Activate.styled";

import InputWrapper from "../../components/InputWrapper";

import Button from "../../components/ui/Button";
import Graphic from "../../components/ui/Graphic";
import InputText from "../../components/ui/InputText";
import Logo from "../../components/ui/Logo";

import {
	useForm
} from "../../contexts/form";

import utilsHelper from "../../helpers/utils";

import useApi from "../../hooks/api";

import i18n from "../../i18n";

// INIT

/**
 * mapStateToProps - ...
 * 
 * @param {object} state ...
 */
const mapStateToProps = (state) => {
	return {
		...state
	};
};

// COMPONENT

const Activate = (props) => {
	const [
		errorCode, 
		setErrorCode
	] = useState("");
	const [
		isAuthenticated, 
		setIsAuthenticated
	] = useState(false);
	const [
		isRenderReady, 
		setIsRenderReady
	] = useState(false);

	const {
		fetchApi
	} = useApi();
	const {
		destroyForm,
		eventHandler,
		initForm,
		inputs,
		inputErrors,
		inputStates,
		setInputStates,
		validateForm
	} = useForm();
	const navigate = useNavigate();

	// Lifecycle

	// Note - Run on bootstrap
	useEffect(
		() => {
			initInputs();

			return () => {
				destroyForm();
			};
		}, 
		[]
	);

	// Note - Run when input change
	useEffect(
		() => {
			if(utilsHelper.isObjectEmpty(inputs)) {
				return;
			}

			if(!isRenderReady) {
				setIsRenderReady(true);
			}
		}, 
		[inputs]
	);

	// Note - Run when inputErrors change
	useEffect(
		() => {
			if(utilsHelper.isObjectEmpty(inputErrors)) {
				return;
			}

			if(inputErrors.authenticationCode) {
				setErrorCode("Invalid entry. Please try again.");
			} else {
				setErrorCode("");
			}
		}, 
		[inputErrors]
	);

	// Events

	/**
	 * goToHome - ...
	 * 
	 * @param {object} e ...
	 */
	const goToHome = (e) => {
		navigate({
			pathname: "/"
		});
	};

	/**
	 * goToLogin - ...
	 * 
	 * @param {object} e ...
	 */
	const goToLogin = (e) => {
		navigate({
			pathname: "/login",
			search: `?email=${props.LOCAL.user.email}`
		});
	};

	/**
	 * submitFormHandler - ...
	 * 
	 * @param {object} e ...
	 */
	const submitFormHandler = async (e) => {
		if(
			!inputStates.isValid || 
			inputStates.isSubmit
		) {
			validateForm(false);

			return;
		}

		setInputStates({
			isSubmit: true
		});

		const loginResponse = await fetchApi({
			data: {
				email: props.LOCAL.user.email,
				token: inputs.authenticationCode.value
			},
			method: "post",
			url: "/login"
		});

		if(!loginResponse.ok) {
			setErrorCode("Invalid entry. Please try again.");
		} else {
			await fetchApi({
				data: {
					email: props.LOCAL.user.email
				},
				headers: {
					token: loginResponse.token
				},
				method: "post",
				url: "/update-registration"
			});

			navigate({
				pathname: "/activate/success"
			});
		}

		setInputStates({
			isSubmit: false
		});
	};

	// Misc
	
	/**
	 * initInputs - ...
	 */
	const initInputs = () => {
		const newForm = {
			authenticationCode: {
				formatter: "",
				initialValue: "",
				inputErrorType: "authenticationCode",
				inputType: "authenticationCode",
				touched: false,
				validators: {
					required: true
				}
			}
		};

		initForm(newForm);
	};

	// Render

	/**
	 * render - ...
	 */
	const render = () => {
		let content;

		if(
			isRenderReady && 
			!utilsHelper.isObjectEmpty(inputs)
		) {
			content = (
				<ScreenInnerWrapper>
					<ScreenStepWrapper>
						<LogoWrapper
							onClick={goToHome}
							>
							<Logo
								name="copdateInsider"
							/>
						</LogoWrapper>
						<StepWrapper>
							{i18n.t(
								"general.step",
								{
									currentStep: 3,
									totalStep: 3
								}
							)}
						</StepWrapper>
					</ScreenStepWrapper>
					<ScreenHeaderWrapper>
						<GraphicWrapper>
							<Graphic
								name="otp"
							/>
						</GraphicWrapper>
						<h1>
							{i18n.t("screens.activate.header.title")}
						</h1>
						<p>
							{i18n.t("screens.activate.header.text")}
						</p>
					</ScreenHeaderWrapper>
					<ScreenBodyWrapper>
						<InputWrapper
							errorText={errorCode}
							>
							<InputText
								name="authenticationCode"
								onBlur={eventHandler}
								onChange={eventHandler}
								placeholder={i18n.t("inputs.placeholder.authenticationCode")}
								{...inputs.authenticationCode}
							/>
						</InputWrapper>
						<Button
							label={i18n.t("actions.continue")}
							loaderActive={inputStates.isSubmit}
							loaderEnable
							onClick={submitFormHandler}
						/>
					</ScreenBodyWrapper>
					<ScreenFooterWrapper>
						<small>
							{i18n.t("screens.activate.footer.alreadyRegistered")} 
							&nbsp;
							<a
								href=""
								onClick={goToLogin}
								>
								{i18n.t("actions.login")}
							</a>
						</small>
					</ScreenFooterWrapper>
				</ScreenInnerWrapper>
			);
		}

		return (
			<ScreenWrapper>
				{content}
			</ScreenWrapper>
		);
	};

	return render();
};

// EXPORT

export default connect(mapStateToProps)(Activate);
