/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import styled from "styled-components";

import {
	columnWrapper,
	rowWrapper
} from "../../styled/styled-extends";
import { 
	setPosition
} from "../../styled/styled-mixins";
import {
	colors,
	fonts
} from "../../styled/styled-variables";

// EXPORT

export const ComponentWrapper = styled.div`
	background-color: ${colors.black};
	padding: 16px;
	transform: translate3d(-50%, 0, 0);
	${setPosition(
		"fixed", 
		{
			"left": "50%",
			"top": 0,
			"z-index": 1
		}
	)}
	
	@media only screen and (min-width: 768px) {
	}

	@media only screen and (min-width: 1024px) {
	}

	@media only screen and (min-width: 1248px) {
	}
`;
