// EXPORT

export const apiConfig = {
	key: "123",
	url: "https://app.trstd.ca/cop"
};

export const appInfo = {
	debug: true,
	env: "development",
	version: "0.0.1"
};

export const languages = [
	{ 
		code: "en",
		label: "English"
	}, {
		code: "fr",
		label: "Français"
	}
];

export default {
	apiConfig,
	appInfo,
	languages
};
