/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import React, {
	Suspense
} from "react";
import * as ReactDOMClient from "react-dom/client";
import initReactFastclick from "react-fastclick";
import {
	Provider
} from "react-redux";
import {
	PersistGate
} from "redux-persist/integration/react";
import {
	ThemeProvider
} from "styled-components";

import App from "./App";

import {
	AppProvider
} from "./contexts/app";
import {
	FormProvider
} from "./contexts/form";

import {
	persistor,
	store
} from "./store";

import {
	themes
} from "./styled/styled-themes";

// INIT

initReactFastclick();

const root = ReactDOMClient.createRoot(
	document.querySelector("#website")
);

root.render(
	<Suspense
		fallback={null}
		>
		<Provider
			store={store}
			>
			<PersistGate
				loading={null}
				persistor={persistor}
				>
				<ThemeProvider
					theme={themes.light}
					>
					<AppProvider>
						<FormProvider>
							<App />
						</FormProvider>
					</AppProvider>
				</ThemeProvider>
			</PersistGate>
		</Provider>
	</Suspense>
);
