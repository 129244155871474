import React, {
	useEffect,
	useState
} from "react";
import {
	NavLink
} from "react-router-dom";

// COMPONENT

const NotFound = (props) => {
	const [
		isRenderReady, 
		setIsRenderReady
	] = useState(false);

	useEffect(
		() => {
			setIsRenderReady(true);
		}, 
		[]
	);

	const render = () => {
		let content = null;

		if(isRenderReady) {
			content = (
				<div
					style={{
						padding: "24px"
					}}
					>
					<h1>Not found</h1>
					<NavLink
						to="/"
						>
						Back home
					</NavLink>
				</div>
			);
		}

		return content;
	};

	return render();
};

// EXPORT

export default NotFound;
