/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import React, {
	useEffect,
	useState
} from "react";
import {
	connect
} from "react-redux";
import {
	useNavigate
} from "react-router-dom";

import {
	GraphicWrapper,
	LogoWrapper,
	ScreenBodyWrapper,
	ScreenHeaderWrapper,
	ScreenInnerWrapper,
	ScreenStepWrapper,
	ScreenWrapper
} from "./ActivateSuccess.styled";

import Button from "../../components/ui/Button";
import Graphic from "../../components/ui/Graphic";
import Logo from "../../components/ui/Logo";

import utilsHelper from "../../helpers/utils";

import i18n from "../../i18n";

// INIT

/**
 * mapStateToProps - ...
 * 
 * @param {object} state ...
 */
const mapStateToProps = (state) => {
	return {
		...state
	};
};

// COMPONENT

const ActivateSuccess = (props) => {
	const [
		isRenderReady, 
		setIsRenderReady
	] = useState(false);

	const navigate = useNavigate();

	// Lifecycle

	// Note - Run on bootstrap
	useEffect(
		() => {
			setIsRenderReady(true);

			return () => {};
		}, 
		[]
	);

	// Events

	/**
	 * goToLogin - ...
	 * 
	 * @param {object} e ...
	 */
	const goToLogin = (e) => {
		navigate({
			pathname: "/login",
			search: `?email=${props.LOCAL.user.email}`
		});
	};

	// Render

	/**
	 * render - ...
	 */
	const render = () => {
		let content;

		if(isRenderReady) {
			content = (
				<ScreenInnerWrapper>
					<ScreenStepWrapper>
						<LogoWrapper>
							<Logo
								name="copdateInsider"
							/>
						</LogoWrapper>
					</ScreenStepWrapper>
					<ScreenHeaderWrapper>
						<GraphicWrapper>
							<Graphic
								name="check"
							/>
						</GraphicWrapper>
						<h1>
							{i18n.t("screens.activateSuccess.header.title")}
						</h1>
						<p>
							{i18n.t("screens.activateSuccess.header.text")}
						</p>
					</ScreenHeaderWrapper>
					<ScreenBodyWrapper>
						<Button
							label={i18n.t("actions.login")}
							onClick={goToLogin}
							style={{
								maxWidth: "280px",
								width: "100%"
							}}
						/>
					</ScreenBodyWrapper>
				</ScreenInnerWrapper>
			);
		}

		return (
			<ScreenWrapper>
				{content}
			</ScreenWrapper>
		);
	};

	return render();
};

// EXPORT

export default connect(mapStateToProps)(ActivateSuccess);
