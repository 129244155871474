/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import PropTypes from "prop-types";
import React from "react";

// INIT

const propTypes = {
	className: PropTypes.string,
	height: PropTypes.string,
	name: PropTypes.string.isRequired,
	vectorEffect: PropTypes.string,
	width: PropTypes.string
};
const defaultProps = {
	className: "",
	vectorEffect: "non-scaling-stroke"
};

// HELPERS

/**
 * getLogoProps - ...
 * 
 * @param {object} props List of props
 */
const getLogoProps = (props) => {
	let logoProps = {
		svgContent: (
			<g></g>
		)
	};

	if(!props.className) {
		logoProps.className = props.className;
	}

	if(props.height) {
		logoProps.height = props.height;
	}

	if(props.width) {
		logoProps.width = props.width;
	}

	switch(props.name) {
		case "copdateInsider":
			logoProps.height = "72px";
			logoProps.svgContent = (
				<g
					fill="none"
					fillRule="nonzero"
					>
					<path
						d="M0 42.1c0-17.85 13.688-29.892 31.797-29.892 15.162 0 22.788 8.837 26.25 16.814l-15.07 7.015c-1.475-4.506-5.98-8.407-11.18-8.407-8.405 0-14.035 6.41-14.035 14.47 0 8.058 5.63 14.469 14.035 14.469 5.2 0 9.705-3.901 11.18-8.407l15.07 6.932c-3.38 7.711-11.088 16.897-26.25 16.897C13.697 71.99 0 59.857 0 42.09v.008Z"
						fill="#FFF"
					/>
					<path
						d="M57.872 42.1c0-17.767 13.78-29.892 31.623-29.892 17.844 0 31.623 12.134 31.623 29.891C121.118 59.857 107.34 72 89.495 72S57.872 59.866 57.872 42.1Zm45.485 0c0-8.06-5.457-14.47-13.862-14.47-8.404 0-13.861 6.41-13.861 14.47 0 8.058 5.457 14.469 13.861 14.469 8.405 0 13.862-6.41 13.862-14.47ZM123.105 70.956V13.16h30.845c13.514 0 20.792 9.011 20.792 19.845S167.464 52.76 153.95 52.76h-13.34v18.197h-17.505Zm33.876-37.95c0-3.124-2.344-4.598-5.283-4.598h-11.087v9.094h11.087c2.948 0 5.283-1.475 5.283-4.506v.01ZM176.207 70.956V13.16h25.819c18.192 0 31.705 10.394 31.705 28.857 0 18.462-13.513 28.939-31.623 28.939h-25.91.01Zm39.772-28.94c0-7.793-4.505-13.599-13.862-13.599h-8.404v27.3h8.313c8.753 0 13.953-6.41 13.953-13.691v-.01ZM271.672 70.956l-2.078-6.759h-20.792l-2.079 6.759h-19.84l21.314-57.796h22.01l21.314 57.796h-19.849Zm-12.479-40.643L253.48 48.94h11.435l-5.713-18.627h-.009ZM294.112 70.956V28.408h-15.509V13.16h48.423v15.248h-15.418v42.548h-17.496zM329.8 70.956V13.16h43.837v15.248h-26.34v5.715h25.727v15.248h-25.727v6.328h26.34v15.248H329.8z"
						fill="#FFF"
					/>
					<path
						d="M398 22.932c0 5.66-4.587 10.247-10.245 10.247-5.658 0-10.245-4.588-10.245-10.247 0-5.66 4.587-10.248 10.245-10.248 5.658 0 10.245 4.588 10.245 10.248Z"
						fill="#FFF"
						stroke="#FFF"
						strokeWidth=".62"
					/>
					<path
						d="M393.02 17.776c-1.41-1.539-3.599-2.739-6.785-2.116-3.26.65-4.843 2.683-5.566 4.689a9.182 9.182 0 0 0-.422 1.658c-.192 1.218-.128 2.27-.036 2.765.293 1.53 1.85 2.601 3.35 2.308 1.484-.293 2.628-1.877 2.335-3.434-.201-1.035.33-2.125 1.42-2.335.585-.12 1.062.018 1.419.293.366.284.64.741.75 1.328.522 2.747-.485 5.082-2.316 6.502a.533.533 0 0 0-.1.732.504.504 0 0 0 .713.101c2.134-1.657 3.296-4.396 2.701-7.537-.155-.806-.54-1.502-1.144-1.969-.604-.467-1.383-.66-2.234-.485-1.785.357-2.509 2.106-2.234 3.562.183.943-.559 2.015-1.52 2.207-.943.183-1.96-.513-2.142-1.474-.064-.357-.138-1.291.046-2.4a7.67 7.67 0 0 1 .375-1.465c.613-1.722 1.95-3.462 4.797-4.02 2.784-.55 4.633.485 5.842 1.804 1.226 1.346 1.785 2.976 1.886 3.517.11.586.174 1.31.201 1.95.028.66 0 1.182-.046 1.383a.524.524 0 0 0 .394.623c.275.064.55-.12.613-.403.074-.348.092-.98.065-1.649-.028-.677-.092-1.456-.22-2.115-.138-.705-.76-2.528-2.143-4.039v.019Zm-.257 4.359c-.64-3.352-3.873-4.634-6.088-4.195-1.154.23-2.317 1.035-3.113 2.134-.806 1.108-1.291 2.555-.998 4.085.055.284.32.467.604.412a.523.523 0 0 0 .403-.614c-.229-1.181.137-2.335.815-3.26.677-.925 1.63-1.566 2.49-1.73 1.804-.358 4.386.713 4.89 3.37.54 2.838-.44 5.503-1.127 6.813a.515.515 0 1 0 .906.495c.742-1.411 1.831-4.332 1.227-7.51h-.009Zm-5.576 1.254a.523.523 0 0 1 .403-.613.517.517 0 0 1 .604.412c.43 2.271-.833 5.568-4.184 6.237a.523.523 0 0 1-.201-1.026c2.682-.531 3.717-3.169 3.37-5.01h.008Z"
						fill="#000"
					/>
					<path
						d="M.302 0h326.724v9.433H.302zM329.681.211h1.566v9.222h-1.566zM340 8.929c-.513-.513-.77-1.255-.77-2.225v-.422h2.106v.312c0 .247.019.467.055.668a1.1 1.1 0 0 0 .22.504c.11.137.247.247.43.32.184.083.403.12.678.12.32 0 .613-.101.87-.312.256-.21.384-.531.384-.97 0-.239-.027-.44-.091-.605a1.168 1.168 0 0 0-.312-.458 2.418 2.418 0 0 0-.576-.375 7.052 7.052 0 0 0-.889-.34 8.125 8.125 0 0 1-1.217-.512 3.124 3.124 0 0 1-.852-.65 2.399 2.399 0 0 1-.503-.861 3.536 3.536 0 0 1-.165-1.136c0-.714.146-1.291.421-1.758h-1.62V9.45h2.646c-.33-.128-.614-.284-.834-.504L340 8.93ZM333.206 2.235h-.036v7.198h2.563zM368.538 9.433h2.645a.988.988 0 0 1-.155-.394 5.982 5.982 0 0 1-.055-.595l-.055-2.006c-.018-.412-.12-.733-.311-.962-.193-.228-.495-.348-.934-.348h-1.126v4.305h-.01ZM370.991 2.097c0-.961-.485-1.447-1.465-1.447h-.998v3.013h.88c.503 0 .897-.119 1.171-.357.275-.238.412-.641.412-1.209ZM365.232.833h-3.726v2.455h3.507v1.721h-3.507v2.812h3.873v1.612h1.053V.211h-1.2zM336.246.211h-2.179l2.151 6.044h.028z"
						fill="#FFC5FF"
					/>
					<path
						d="M344.303 9.433h3.03V.21h-1.858c.036.073.091.137.128.21.165.357.238.779.238 1.282v.293h-2.014c0-.494-.092-.879-.266-1.144-.174-.266-.467-.403-.879-.403-.238 0-.43.036-.586.1a.977.977 0 0 0-.375.275.953.953 0 0 0-.192.385 1.9 1.9 0 0 0-.055.458c0 .32.064.595.201.815.138.22.43.421.88.604l1.62.705c.402.174.723.358.98.55.256.192.457.394.604.613.155.22.256.458.31.724.056.266.092.559.092.88 0 1.098-.32 1.904-.96 2.408a3.213 3.213 0 0 1-.916.476l.018-.01ZM359.4.21h-2.234c.064.092.147.165.202.266.247.44.42.98.503 1.603.092.623.128 1.319.128 2.088 0 .916-.054 1.712-.164 2.39-.11.678-.302 1.236-.577 1.676-.275.44-.641.77-1.099.98-.201.092-.43.156-.668.21h3.9V.202l.01.01ZM372.722.21c.247.413.375.935.375 1.576 0 .705-.137 1.273-.421 1.722-.284.448-.723.732-1.319.851v.028c.531.082.916.247 1.154.513.247.265.394.686.458 1.272.018.193.036.403.046.632 0 .23.018.486.027.77.018.558.046.98.092 1.273.055.274.174.458.347.577h.028V.2h-.787v.01ZM355.06.88c-.237-.157-.558-.23-.96-.23h-.889v7.345h.934c.366 0 .668-.064.888-.192.22-.129.403-.34.531-.632.128-.293.22-.678.266-1.154.045-.476.064-1.072.064-1.786 0-.595-.019-1.117-.055-1.566-.037-.449-.12-.824-.238-1.117a1.39 1.39 0 0 0-.54-.678v.01ZM349.439.211h1.666v9.222h-1.666z"
						fill="#FFC5FF"
					/>
				</g>
			);
			logoProps.viewBox = "0 0 399 72";
		break;
	}

	return logoProps;
};

// COMPONENT

const Logo = (props) => {
	const {
		svgContent,
		...svgProps
	} = getLogoProps(props);

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			{...svgProps}
			>
			{svgContent}
		</svg>
	);
};

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;

// EXPORT

export default Logo;
