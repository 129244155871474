/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import React, {
	useEffect,
	useState
} from "react";
import {
	connect
} from "react-redux";
import {
	useNavigate
} from "react-router-dom";

import {
	LogoWrapper,
	ProductContentWrapper,
	ProductWrapper,
	ScreenBodyWrapper,
	ScreenFooterWrapper,
	ScreenHeaderWrapper,
	ScreenInnerWrapper,
	ScreenStepWrapper,
	ScreenWrapper,
	TopLogoWrapper
} from "./Account.styled";

import Button from "../../components/ui/Button";
import Logo from "../../components/ui/Logo";

import storeHelper from "../../helpers/store";
import utilsHelper from "../../helpers/utils";

import useApi from "../../hooks/api";

import i18n from "../../i18n";

// INIT

/**
 * mapStateToProps - ...
 * 
 * @param {object} state ...
 */
const mapStateToProps = (state) => {
	return {
		...state
	};
};

// COMPONENT

const Account = (props) => {
	const [
		checkoutUrl, 
		setCheckoutUrl
	] = useState(null);
	const [
		isRenderReady, 
		setIsRenderReady
	] = useState(false);
	const [
		product, 
		setProduct
	] = useState(null);

	const {
		fetchApi
	} = useApi();
	const navigate = useNavigate();

	// Lifecycle

	// Note - Run on bootstrap
	useEffect(
		() => {
			if(!props.LOCAL.user.authToken) {
				logoutHandler();
			}

			const waitAsync = async () => {
				const orderResponse = await fetchApi({
					headers: {
						token: props.LOCAL.user.authToken
					},
					params: {
						email: props.LOCAL.user.email
					},
					method: "get",
					url: "/generate-order"
				});

				if(!orderResponse.ok) {
					setIsRenderReady(true);
		
					return;
				}
		
				setCheckoutUrl(orderResponse.data);

				const productResponse = await fetchApi({
					headers: {
						token: props.LOCAL.user.authToken
					},
					params: {
						email: props.LOCAL.user.email
					},
					method: "get",
					url: "/get-product-info"
				});
		
				if(productResponse.data) {
					setProduct(productResponse.data);
				} else {
					setIsRenderReady(true);
				}
			};

			waitAsync();

			return () => {};
		}, 
		[]
	);

	// Note - Run when product change
	useEffect(
		() => {
			if(!product) {
				return;
			}

			if(!isRenderReady) {
				setIsRenderReady(true);
			}
		}, 
		[product]
	);

	// Events

	/**
	 * goToFaq - ...
	 * 
	 * @param {object} e ...
	 */
	const goToFaq = (e) => {
		navigate({
			pathname: "/faq"
		});
	};

	/**
	 * logoutHandler - ...
	 * 
	 * @param {object} e ...
	 */
	const logoutHandler = (e) => {
		storeHelper.dispatch("RESET_USER");

		navigate({
			pathname: "/login"
		});
	};

	/**
	 * submitFormHandler - ...
	 * 
	 * @param {object} e ...
	 */
	const submitFormHandler = async (e) => {
		if(!checkoutUrl) {
			return;
		}

		window.location.href = checkoutUrl;
	};
	
	// Render

	/**
	 * render - ...
	 */
	const render = () => {
		let content;

		if(isRenderReady) {
			if(product) {
				let buttonContent;

				if(checkoutUrl) {
					buttonContent = (
						<Button
							label={i18n.t("actions.continue")}
							onClick={submitFormHandler}
						/>
					);
				}

				content = (
					<ScreenInnerWrapper>
						<ScreenStepWrapper>
							<TopLogoWrapper>
								<Logo
									name="copdateInsider"
								/>
							</TopLogoWrapper>
						</ScreenStepWrapper>
						<ScreenHeaderWrapper>
							<h2>
								{i18n.t(
									"screens.account.header.default.title",
									{
										firstName: props.LOCAL.user.firstName
									}
								)}
							</h2>
							<p>
								{i18n.t("screens.account.header.default.text")}
							</p>
						</ScreenHeaderWrapper>
						<ScreenBodyWrapper>
							<ProductWrapper>
								<img
									src={product.productImage}
								/>
								<ProductContentWrapper>
									<strong>
										{product.name}
									</strong>
									<small>
										{product.variant}
									</small>
								</ProductContentWrapper>
								{buttonContent}
							</ProductWrapper>
							<Button
								label={i18n.t("actions.logout")}
								onClick={logoutHandler}
								style={{
									marginTop: "40px",
									maxWidth: "280px",
									width: "100%"
								}}
							/>
						</ScreenBodyWrapper>
						<ScreenFooterWrapper>
						<small>
							<a
								onClick={goToFaq}
								>
								FAQ
							</a>
						</small>
					</ScreenFooterWrapper>
					</ScreenInnerWrapper>
				);
			} else {
				content = (
					<ScreenInnerWrapper>
						<ScreenHeaderWrapper>
							<LogoWrapper>
								<Logo
									name="copdateInsider"
								/>
							</LogoWrapper>
							<h2>
								{i18n.t(
									"screens.account.header.empty.title",
									{
										firstName: props.LOCAL.user.firstName
									}
								)}
							</h2>
							<p>
								{i18n.t("screens.account.header.empty.text")}
							</p>
						</ScreenHeaderWrapper>
						<ScreenBodyWrapper>
							<Button
								label={i18n.t("actions.logout")}
								onClick={logoutHandler}
								style={{
									maxWidth: "280px",
									width: "100%"
								}}
							/>
						</ScreenBodyWrapper>
						<ScreenFooterWrapper>
						<small>
							Follow
							&nbsp;
							<a
								href="https://www.instagram.com/copdate"
								>
								@copdate
							</a>
							&nbsp;
							for our latest events & Perks Pass Exclusives.
						</small>
						<small>
							<a
								onClick={goToFaq}
								>
								FAQ
							</a>
						</small>
					</ScreenFooterWrapper>
					</ScreenInnerWrapper>
				);
			}
		}

		return (
			<ScreenWrapper>
				{content}
			</ScreenWrapper>
		);
	};

	return render();
};

// EXPORT

export default connect(mapStateToProps)(Account);
