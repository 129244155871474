/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import React, {
	useEffect,
	useState
} from "react";
import {
	connect
} from "react-redux";
import {
	useLocation,
	useNavigate
} from "react-router-dom";

import {
	DownloadLinkWrapper,
	DownloadWrapper,
	GraphicWrapper,
	LogoWrapper,
	ScreenBodyWrapper,
	ScreenFooterWrapper,
	ScreenHeaderWrapper,
	ScreenInnerWrapper,
	ScreenStepWrapper,
	ScreenWrapper,
	StepWrapper
} from "./Download.styled";

import Button from "../../components/ui/Button";
import Graphic from "../../components/ui/Graphic";
import Logo from "../../components/ui/Logo";

import utilsHelper from "../../helpers/utils";

import i18n from "../../i18n";

// INIT

/**
 * mapStateToProps - ...
 * 
 * @param {object} state ...
 */
const mapStateToProps = (state) => {
	return {
		...state
	};
};

// COMPONENT

const Download = (props) => {
	const [
		isRenderReady, 
		setIsRenderReady
	] = useState(false);

	const { 
		state
	} = useLocation();
	const navigate = useNavigate();

	// Lifecycle

	// Note - Run on bootstrap
	useEffect(
		() => {
			const newState = 
				state ||
				{}
			; 

			if(!newState.qrcode) {
				navigate({
					pathname: "/",
					search: `?email=${props.LOCAL.user.email}`
				});

				return;
			}

			setIsRenderReady(true);

			return () => {};
		}, 
		[]
	);

	// Events

	/**
	 * goToQrcodeHandler - ...
	 * 
	 * @param {object} e ...
	 */
	const goToQrcodeHandler = (e) => {
		navigate(
			{
				pathname: "/qrcode"
			}, {
				state: state
			}
		);
	};

	/**
	 * goToLogin - ...
	 * 
	 * @param {object} e ...
	 */
	const goToLogin = (e) => {
		navigate({
			pathname: "/login"
		});
	};

	// Render

	/**
	 * render - ...
	 */
	const render = () => {
		let content;

		if(isRenderReady) {
			content = (
				<ScreenInnerWrapper>
					<ScreenStepWrapper>
						<LogoWrapper>
							<Logo
								name="copdateInsider"
							/>
						</LogoWrapper>
						<StepWrapper>
							{i18n.t(
								"general.step",
								{
									currentStep: 1,
									totalStep: 3
								}
							)}
						</StepWrapper>
					</ScreenStepWrapper>
					<ScreenHeaderWrapper>
						<GraphicWrapper>
							<Graphic
								name="trstdApp"
							/>
						</GraphicWrapper>
						<h1>
							{i18n.t("screens.download.header.title")}
						</h1>
						<p>
							{i18n.t("screens.download.header.text")}
						</p>
					</ScreenHeaderWrapper>
					<ScreenBodyWrapper>
						<DownloadWrapper>
							<small>
								{i18n.t("screens.download.body.downloadTrstd")}
							</small>
							<DownloadLinkWrapper>
								<a
									href="https://apps.apple.com/ca/app/trstd/id6444154787"
									>
									{i18n.t("actions.appStore")}
								</a>
								<a
									href="https://play.google.com/store/apps/details?id=com.app.trstd"
									>
									{i18n.t("actions.googlePlay")}
								</a>
							</DownloadLinkWrapper>
						</DownloadWrapper>
						<Button
							label={i18n.t("actions.continue")}
							onClick={goToQrcodeHandler}
							style={{
								maxWidth: "280px",
								width: "100%"
							}}
						/>
					</ScreenBodyWrapper>
					<ScreenFooterWrapper>
						<small>
							{i18n.t("screens.home.footer.alreadyRegistered")} 
							&nbsp;
							<a
								href=""
								onClick={goToLogin}
								>
								{i18n.t("actions.login")}
							</a>
						</small>
					</ScreenFooterWrapper>
				</ScreenInnerWrapper>
			);
		}

		return (
			<ScreenWrapper>
				{content}
			</ScreenWrapper>
		);
	};

	return render();
};

// EXPORT

export default connect(mapStateToProps)(Download);
