/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// INIT

const initialState = {
	description: "",
	iconUrl: "",
	name: "",
	url: ""
};

// EXPORT

export default (state = initialState, action) => {
	switch(action.type) {
		case "SET_APP_INFO":
		return {
			...state,
			...action.payload
		};
		default:
		return state;
	}
};
