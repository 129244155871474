/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import PropTypes from "prop-types";
import React from "react";

import {
	ComponentWrapper
} from "./Notification.styled";

// INIT

const propTypes = {
	visible: PropTypes.bool
};
const defaultProps = {
	visible: false
};

// COMPONENT

const Notification = (props) => {
	// Render

	/**
	 * render - ...
	 */
	const render = () => {
		if(!props.visible) {
			return null;
		}

		return (
			<ComponentWrapper>
				Notification
			</ComponentWrapper>
		);  
	};

	return render();
};

Notification.propTypes = propTypes;
Notification.defaultProps = defaultProps;

// EXPORT

export default Notification;
