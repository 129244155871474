/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import React from "react";

import {
	apiRequest
} from "../api";

// HOOK

const useApi = () => {
	// Methods

	/**
	 * fetchApi - ... 
	 */
	const fetchApi = (apiRequestOptions) => {
		apiRequestOptions = 
			apiRequestOptions || 
			{}
		;

		return apiRequest(apiRequestOptions);
	};

	// Return

	return {
		fetchApi
	};
};

// EXPORT

export default useApi;
