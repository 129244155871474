/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import {
	combineReducers
} from "redux";

import appReducer from "./app";
import oauthAppReducer from "./oauthApp";
import userReducer from "./user";

// EXPORT

export const localReducer = combineReducers({
	user: userReducer
});

export const sessionReducer = combineReducers({
	app: appReducer,
	oauthApp: oauthAppReducer
});
