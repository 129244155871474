import {
	transparentize
} from "polished";
import styled, {
	keyframes
} from "styled-components";

const loaderAnimation = keyframes`
	from {
		transform: rotate(0deg);
  	}
	to {
		transform: rotate(360deg);
	}
`;

export const ComponentRoot = styled.div`
	animation: ${loaderAnimation} 800ms linear infinite;
	border-radius: 50%;
	border: 4px solid transparent;
	box-sizing: border-box;
	height: 48px;//24px;
	width: 48px;//24px;

	${(props) => {
		const {
			background
		} = props.theme;

		return `
			border-top-color: ${transparentize(0.75, background.colors.level1)};
		`;
	}};
`;
