import {
	apiConfig
} from "../configs/core";

import {
	isObject,
	isObjectEmpty
} from "../helpers/utils";

import storeHelper from "../helpers/store";

// METHODS

/**
 * getApiKey - ...
 */
const getApiKey = () => {
	return apiConfig.key;
};

/**
 * getApiUrl - ...
 */
const getApiUrl = () => {
	return apiConfig.url;
};

/**
 * getAuthToken - ...
 */
const getAuthToken = () => {
	const store = storeHelper.getState();

	return store.LOCAL.user.authToken;
};

/**
 * parseCatchError - Parse catch error.
 * 
 * @param {object} rawError Error object to parse
 */
const parseCatchError = (rawError) => {
	rawError = 
		rawError ||
		{}
	;

	let captureException = false;
	let error = {};
	let response = 
		rawError.response || 
		{}
	;

	if(!isObjectEmpty(response.data)) {
		error = response.data;
	}

	return error;
};

// EXPORT

export default {
	getApiKey,
	getApiUrl,
	getAuthToken,
	parseCatchError
};
