import {
	css
} from "styled-components";

import { 
	setFont,
	setPosition
} from "./styled-mixins";

export const columnWrapper = css`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
`;

export const disableSelect = css`
	user-select: none;
`;

export const rowWrapper = css`
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
`;

export const verticalScroller = css`
	overflow-y: auto;
	::-webkit-scrollbar {
		display: none;
	}
`;







export const bodyWrapper = css`
	align-self: center;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	max-width: 1280px;
	padding: 24px 48px;
	width: 100%;
`;

export const headerWrapper = css`
	align-self: center;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	max-width: 1280px;
	padding: 24px 48px;
	width: 100%;
`;









export const disabled = css`
	&.disabled,
	.disabled {
		cursor: not-allowed;
		pointer-events: none;
	}
`;

export const ellipsis = css`
	overflow: hidden;
	width: 100%;
	box-sizing: border-box;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const fill = css`
	&.fill,
	.fill {
		flex: 1;
	}
`;

export const loaderWrapper = css`
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	pointer-events: none;
	transition: opacity 200ms;
	width: 100%;
	will-change: opacity;
	${setPosition("absolute", {
		"left": 0,
		"top": 0,
		"z-index": 1
	})}
`;


