/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	- useLocation here to manage both header & sidebar?

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import React, {
	useEffect,
	useState
} from "react";
import {
	withTranslation
} from "react-i18next";
import {
	connect
} from "react-redux";
import {
	BrowserRouter,
	Navigate,
	Route,
	Routes
} from "react-router-dom";
import {
	compose
} from "redux";

import router from "./router";

import Loader from "./components/Loader";
import Modal from "./components/Modal";
import Notification from "./components/Notification";
import PrivateRoutes from "./components/PrivateRoutes";
import Sidebar from "./components/Sidebar";

import Container from "./components/ui/Container";

import GlobalStyles from "./styled/styled-globals";

// INIT

/**
 * mapStateToProps - ...
 * 
 * @param {object} state ...
 */
const mapStateToProps = (state) => {
	return {
		...state
	};
};

// COMPONENT

const App = (props) => {
	const [
		isRenderReady, 
		setIsRenderReady
	] = useState(false);
	const [
		sidebarProps, 
		setSidebarProps
	] = useState({
		isUserConnected: false
	});

	// Lifecycle

	// Note - Run on bootstrap
	useEffect(
		() => {}, 
		[]
	);

	// Note - Run when authToken change
	useEffect(
		() => {
			setSidebarProps({
				isUserConnected: props.LOCAL.user.authToken ?
					true :
					false
			});
    	}, 
		[props.LOCAL.user.authToken]
	);

	// Render

	/**
	 * render - ...
	 */
	const render = () => {
		return (
			<BrowserRouter>
				<GlobalStyles />
				
				<Loader />
				<Notification />

				<Container
					id="website-view"
					>
					<Container
						id="website-body"
						>
						<Container
							id="website-content"
							>
							<Routes>
								{router.map((routeItem) => {
									const Component = routeItem.component;
						
									return (
										<Route
											element={<Component />}
											key={routeItem.path}
											path={routeItem.path}
										/>
									);
								})}
							</Routes>
						</Container>
					</Container>
				</Container>
			</BrowserRouter>
		);
	};

	return render();
};

// EXPORT

export default compose(
	withTranslation(), 
	connect(mapStateToProps)
)(App);
