// EXPORT

export default {
	account: {
		componentName: "Account",
		url: "account"
	},
	activate: {
		componentName: "Activate",
		url: "activate"
	},
	activateSuccess: {
		componentName: "ActivateSuccess",
		url: "activate/success"
	},
	download: {
		componentName: "Download",
		url: "download"
	},
	faq: {
		componentName: "Faq",
		url: "faq"
	},
	home: {
		componentName: "Home",
		url: ""
	},
	login: {
		componentName: "Login",
		url: "login"
	},
	qrcode: {
		componentName: "QrCode",
		url: "qrcode"
	},
	welcome: {
		componentName: "Welcome",
		url: "welcome"
	}
};
