"use strict";
/*
	NOTE
	- 

	TODO
	- 

	NICE TO HAVE/NEXT
	- 
*/
// HELPERS

/**
 * formatCurrency - ...
 * 
 * @param {string} value Value to format
 * @param {int} decimal Number of decimal
 */
const formatCurrency = (value, decimal = 2) => {
	if(!isNumber(value)) {
		return;
	}

	return value.toLocaleString(
		"en-US", 
		{
			currency: "USD",
			currencyDisplay: "symbol",
			maximumFractionDigits: decimal,
			minimumFractionDigits: decimal,
			style: "currency"
		}
	);
};

/**
 * inArray - Check if value is found within an array.
 * 
 * @param {mized} value Value to check
 * @param {mized} array Array to check against
 */
const inArray = (value, array) => {
	if(!isArray(array)) {
		return false;
	}

	return array.includes(value);
};

/**
 * isArray - Check if value is an array.
 * 
 * @param {mized} value Value to check
 */
const isArray = (value) => {
	return (
		!!value && 
		value.constructor === Array
	);
};

/**
 * isArrayEmpty - Check if value is an empty array.
 * 
 * @param {mized} value Value to check
 */
const isArrayEmpty = (value) => {
	if(!isArray(value)) {
		return true;
	}

	return (
		value.length ? 
		false : 
		true
	);
};

/**
 * isBoolean - Check if value is a boolean.
 * 
 * @param {mized} value Value to check
 */
const isBoolean = (value) => {
	return typeof value === "boolean";
};

/**
 * isFunction - Check if value is a function.
 * 
 * @param {mized} value Value to check
 */
const isFunction = (value) => {
	return typeof value === "function";
};

/**
 * isNumber - Check if value is a number.
 * 
 * @param {mized} value Value to check
 */
const isNumber = (value) => {
	return (
		typeof value === "number" && 
		isFinite(value)
	);
};

/**
 * isObject - Check if value is a literal object.
 * 
 * @param {mized} value Value to check
 */
const isObject = (value) => {
	return (
		!!value && 
		value.constructor === Object
	);
};

/**
 * isObjectEmpty - ...
 * 
 * @param {int} size String size
 */
const isObjectEmpty = (value) => {
	if(!isObject(value)) {
		return true;
	}

	return Object.keys(value).length === 0;
};

/**
 * isObjectHasKey - ...
 * 
 * @param {object} object Object
 * @param {string} key Object key
 */
const isObjectHasKey = (object, key) => {
	return Object.hasOwn(
		object, 
		key
	);
};

/**
 * isString - Check if value is a string.
 * 
 * @param {mixed} value Value to check
 */
const isString = (value) => {
	return typeof value === "string";
};

/**
 * isStringEmpty - Check if value is an empty string.
 * 
 * @param {mized} value Value to check
 */
const isStringEmpty = (value) => {
	if(!isString(value)) {
		return true;
	}

	return (
		value ? 
		false : 
		true
	);
};



// why not in form context?
const validationPatterns = {
	email: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/,
	postalCode: /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i,
	url: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
	zipCode: /^\d{5}(?:[-\s]\d{4})?$/,
	password: /^(?=.*[A-Z])(?=.*[\W]{0,})(?=.*[0-9])(?=.*[a-z]).{8,}$/,
	phoneNumber: /^\d{10}$/
};

// EXPORT

module.exports = {
	formatCurrency,
	inArray,
	isArray,
	isArrayEmpty,
	isBoolean,
	isFunction,
	isNumber,
	isObject,
	isObjectEmpty,
	isObjectHasKey,
	isString,
	isStringEmpty,
	validationPatterns
};
