/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import {
	createGlobalStyle
} from "styled-components";

import {
	columnWrapper,
	rowWrapper
} from "./styled-extends";
import { 
	setFont
} from "./styled-mixins";
import {
	colors,
	fonts
} from "./styled-variables";

// INIT

const globalStyles = createGlobalStyle`
	@font-face {
		font-family: "Roc Grotesk";
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		src: url("../assets/fonts/rocgrotesk-medium.otf") format("opentype");
	}

	* {
		margin: 0;
		padding: 0;
	}
	
	html {
		height: -webkit-fill-available;
	}

	body {
		background-color: #000;
		color: ${colors.grey};
		font-family: "Inter";
		font-size: 14px;
		font-weight: 400;
		line-height: 1.5;




		display: flex;
		/*height: 100%;*/
		margin: 0;
		overflow-y: auto;
		overflow-x: hidden;
		//overscroll-behavior: contain; /* Supposed to block side scroll-chaining. Seem to not working in Safari */
		padding: 0;
		/*text-size-adjust: 100%;*/
		width: 100%; /*using 100vh; make scrollbar appear over content padding*/
		min-height: 100vh;
		min-height: -webkit-fill-available;
		/*word-break: break-all;*/
		-webkit-tap-highlight-color: transparent;
		-webkit-font-smoothing: antialiased;
	}

	#website {
		min-height: 100vh;
		min-height: -webkit-fill-available;
		overflow: hidden;
		width: 100%;
		-webkit-overflow-scrolling: touch;
		${columnWrapper};
	}

	#website-body,
	#website-content {
		flex: 1;
		${columnWrapper};
	}

	#website-content {
		align-items: center;
	}

	#website-view {
		flex: 1;
		${rowWrapper};
	}

	h1,
	h2 {
		color: #FFF;
		font-family: "Roc Grotesk";
		font-weight: 500;
		line-height: 1.25;
	}

	h1 {
		font-size: 32px;
	}

	h2 {
		font-size: 24px;
	}

	input::placeholder {
		color: #747474;
	}

	input:-webkit-autofill {
		border-radius: inherit;
		-webkit-box-shadow: 0 0 0px 1000px #252527 inset;
		-webkit-text-fill-color: #FFF;
	}

	p {
		padding-top: 16px;

		&:empty {
			display: none;
		}
	}

	a {
		color: #EEE;
		cursor: pointer;
		font-weight: 500;
		text-decoration: none;
	}

	small {
		font-size: 14px;
	}

	strong {
		font-weight: 700;
	}

	@media only screen and (min-width: 768px) {
		body {
			font-size: 16px;
		}
	}
`;

// EXPORT

export default globalStyles;
