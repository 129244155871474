/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import PropTypes from "prop-types";
import React from "react";

import {
	ComponentWrapper,
	ErrorWrapper,
	HelpWrapper,
	LabelWrapper
} from "./InputWrapper.styled";

// INIT

const propTypes = {
	errorText: PropTypes.string,
	helpText: PropTypes.string,
	label: PropTypes.node,
	required: PropTypes.bool
};
const defaultProps = {};

// COMPONENT

const InputWrapper = (props) => {
	let errorContent;
	let helpContent;
	let labelContent;

	const {
		children,
		errorText,
		helpText,
		label,
		...childrenProps // should goes to children?
	} = props;

	if(errorText) {
		errorContent = (
			<ErrorWrapper>
				{errorText}
			</ErrorWrapper>
		);
	}

	if(helpText) {
		helpContent = (
			<HelpWrapper>
				{helpText}
			</HelpWrapper>
		);
	}

	if(label) {
		let labelWrapperProps = {};

		if(childrenProps.id) {
			labelWrapperProps.htmlFor = childrenProps.id;
		}

		labelContent = (
			<LabelWrapper
				{...labelWrapperProps}
				>
				{label}
			</LabelWrapper>
		);
	}

    return (
		<ComponentWrapper>
			{labelContent}
			{children}
			{helpContent}
			{errorContent}
		</ComponentWrapper>
	);
};

InputWrapper.propTypes = propTypes;
InputWrapper.defaultProps = defaultProps;

// EXPORT

export default InputWrapper;
