/**
 * @format
 * @flow strict-local
 */
/*
	NOTE
	- 

	TODO
	-

	NICE TO HAVE/NEXT
	- 
*/
// DEPENDENCIES

import React from "react";
import {
	Trans
} from "react-i18next";

// HELPERS

/**
 * renderTrans - ...
 * 
 * @param {string} i18nKey ...
 * @param {object} values ...
 * @param {object} components ...
 */
const renderTrans = (i18nKey, values, components) => {
	if(!i18nKey) {
		return;
	}

	components = 
		components || 
		{}
	;
	values = 
		values || 
		{}
	;

	return (
		<Trans
			components={components}
			i18nKey={i18nKey}
			values={values}
		/>
	);
};

// EXPORT

export default {
	renderTrans
};