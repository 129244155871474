import {
	lighten,
	transparentize
} from "polished";

export const colors = {
	athensGray: "#F2F3F6", // light grey
	black: "#000",
	daintree: "#002C3E", // dark blue
	linen: "#FBEBE9", // light orange
	ottoman: "#E6F7EC", // light green
	polar: "#E7F7F9", // light blue
	serenade: "#FFF3E6", // light yellow
	sunsetOrange: "#FE4D3D", // dark orange
	transparent: "transparent",
	white: "#FFF",


	//... old






	
	boulder: "#757575",
	corn: "#F8C509",
	dodgerBlue: "#3FAFFE",
	dusty: "#999",
	gallery: "#EEE",
	
	mountainMeadow: "#25C06E",
	
	white: "#FFF",
	wildSand: "#F5F5F5",

	// to clean

	
	azureRadiance: "#018FF5", // [NOTE] Primary color
	black: "#000",
	boulder: "#757575",
	flamingo: "#EA5030", // [NOTE] Danger color
	gallery: "#EEE",
	mercury: "#E5E5E5",
	mineShaft: "#222",
	mountainMeadow: "#1EB972", // [NOTE] Success color
	tundora: "#444",
 	
	wildSand: "#F5F5F5",

	
	



	
	concrete: "#F2F2F2",
	doveGrey: "#666",
	dusty: "#999", // to remove
	elephant: "#0C3240",
	fruitSalad: "#4CAF50", // success
	scienceBlue: "#0073d0",
	grey: "#888",
			malachite: "#0CC260",
	
	pictonBlue: "#2CBCF0", // primary
	primaryBackground: "#2CBCF0",
	primaryForeground: "#FFF",
	silver: "#CCC",
	silverLight: "#CCC",
	silverDark: "#C5C5C5",
	silverChalice: "#AAA"
};

export const fonts = {
	lora: `"Lora", serif`,
	openSans: `"Open Sans", sans-serif`,
	poppins: `"Poppins", sans-serif`,
	roboto: `"Roboto", sans-serif`,
	robotoCondensed: `"Jaldi", sans-serif`
};

export const typo = {
	fontFamilies: {
		lora: `"Lora", serif`,
		openSans: `"Open Sans", sans-serif`,
		poppins: `"Poppins", sans-serif`,
		roboto: `"Roboto", sans-serif`,
		robotoCondensed: `"Jaldi", sans-serif`
	},
	fontSizes: {
		default: "16px",
		h1: "32px",
		h2: "24px",
		large: "18px",
		larger: "20px",
		small: "14px",
		smaller: "12px"
	},
	lineHeight: 1.5
};

export const indexes = {
	notification: 999,
	header: 998,
	sidebar: 997,
	modal: 997,


	appLoader: 996,
	sticky: 994,
	footer: 993,
	loader: 996
};

export const themes = {
	dark: {},
	light: {
		container: {
			background: {
				colors: {
					default: colors.white,
					header: colors.mercury
				}
			},
			border: {
				colors: {
					inner: colors.alto,
					outer: colors.altoDark
				}
			}
		},


		// should use container component
		card: {
			background: {
				color: colors.wildSand
			},
			border: {
				colors: {
					inner: colors.mercury,
					outer: colors.altoDark
				}
			}
		},


		navbar: {
			border: {
				colors: {
					active: colors.mineShaft,
					hover: colors.gallery
				}		
			},
			typo: {
				colors: {
					active: colors.mineShaft,
					default: colors.boulder
				}
			}
		},
		notification: {
			background: {
				colors: {
					closeButton: transparentize(0.8, colors.white),
					default: colors.mineShaft
				}
			},
			border: {
				colors: {
					danger: colors.flamingo,
					default: colors.tundora,
					primary: colors.azureRadiance,
					success: colors.mountainMeadow
				}		
			},
			typo: {
				colors: {
					text: colors.alto,
					title: colors.white
				}
			}
		},

		app: {
			background: {
				color: colors.wildSand
			}
		},

		box: {
			background: {
				color: colors.white
			},
			border: {
				color: colors.silver
			}
		},
		/*
		accordion: {
			background: {
				color: colors.white
			},
			border: {
				color: colors.silver
			},
			navItems: {
				background: {
					colors: {
						active: colors.concrete
					}
				},
				border: {
					colors: {
						active: colors.mineShaft,
						hover: colors.silver
					}
				},
				typo: {
					colors: {
						active: colors.mineShaft,
						default: colors.grey,
						hover: colors.doveGrey
					}
				}
			}
		},
		*/
		header: {
			// auth...
			default: {
				background: colors.white,
				border: colors.alto,
				foreground: colors.mineShaft
			},
			mobile: {
				background: colors.black,
				border: colors.transparent,
				foreground: colors.white
			}
		},
		sidebar: {
			background: colors.black,
			border: transparentize(0.9, colors.white),
			footer: {
				foreground: colors.tundora
			},
			navlink: {
				active: {
					background: transparentize(0.9, colors.white),
					foreground: colors.white
				},
				default: {
					background: colors.transparent,
					foreground: colors.tundora
				}
			},


			// header
			
			nav: {
				typo: {
					colors: {
						active: colors.white,
						default: colors.grey
					}
				}
			}
		},



		screen: {
			body: {
				background: colors.wildSand
			},
			header: {
				background: colors.white,
				border: colors.alto,
				foreground: colors.mineShaft
			}
		},
		/*
		color: {
			grey: {
				...
			}
		}
		*/
		background: {
			colors: {
				level001: colors.sharkDark,
				level002: colors.sharkLight,
				level003: colors.abbey,
				level1: colors.black,		// #000
				level2: colors.mercury,		// #E5E5E5
				level3: colors.gallery,		// #EEE
				level4: colors.wildSand,	// #F5F5F5
				level5: colors.white		// #FFF
			}
		},
		button: {
			border: {
				radius: "5px",
				width: "2px"
			}
			// transitionDuration ?
		},
		cardStat: {
			background: {
				color: {
					default: colors.wildSand,
					hover: colors.gallery
				}
			}
		},
		input: {
			background: {
				colors: {
					default: colors.white,
					disabled: colors.wildSand,				// #F5F5F5
					error: lighten(0.35, colors.sunsetOrange),
					focus: colors.white
				}
			},
			border: {
				colors: {
					default: colors.silver,					// #CCC
					disabled: colors.alto,					// #DDD
					error: lighten(0.15, colors.sunsetOrange),
					focus: colors.silverChalice				// #AAA
				}
			},
			typo: {
				colors: {
					default: colors.black,
					disabled: colors.silver,				// #CCC
					placeholder: colors.boulder				// #757575
				}
			}
		},
		
		backgroundColors: {
			level0: colors.block,
			level1: colors.sharkDark,
			level2: colors.sharkLight,
			level3: colors.abbey,
			level31: colors.wildSand,
			level32: colors.alabaster,
			level4: colors.white
		},
		paletteColors: {
			black: {
				background: colors.black,
				foreground: colors.white
			},
			ghost: {
				background: "inherit", 
				foreground: "currentColor"
			},
			default: {
				background: "#ccc",//colors.grey,
				foreground: colors.white
			},
			disabled: {
				background: "#CCC", //colors.black,
				foreground: colors.white
			},


			//black: colors.black,
			primary: colors.azureRadiance,
			white: colors.white,

			dark: {
				background: colors.black,
				foreground: colors.white
			},
			// dark
			// light
			
			danger: {
				background: colors.flamingo,
				foreground: colors.white
			},
			
			doveGrey: {
				background: colors.doveGrey,
				foreground: colors.white
			},
			
			success: {
				background: colors.malachite,
				foreground: colors.white
			}
		},
		profileNavbar: {
			background: {
				color: colors.white
			},
			border: {
				color: colors.alto
			},
			navItems: {
				background: {
					colors: {
						active: colors.concrete
					}
				},
				border: {
					colors: {
						active: colors.mineShaft,
						hover: colors.alto
					}
				},
				typo: {
					colors: {
						active: colors.mineShaft,
						default: colors.grey,
						hover: colors.doveGrey
					}
				}
			}
		},
		statusColors: {
			danger: colors.sunsetOrange,
			dark: colors.doveGrey,
			default: colors.boulder,
			info: colors.dodgerBlue,
			light: colors.dusty,
			success: colors.mountainMeadow,
			warning: colors.corn,
			//... depreciated
			active: "#25c06e",//colors.malachite,
			//default: colors.boulder,
			inactive: "#fe5147",//colors.flamingo,
			disabled: "#f8c509",//"#3faffe"//colors.scienceBlue
			pending: "#3faffe",//"#3faffe"//colors.scienceBlue
			primary: colors.azureRadiance
			/*
			active: "#38c875",//colors.malachite,
			default: "#ffa13a",//colors.grey,
			inactive: "#fd504e",//colors.flamingo,
			pending: "#3faffe"//colors.scienceBlue
			*/
		},
		typoColors: {
			level1: "#000",
			level2: "#222",
			level3: "#444",
			level4: "#666",
			level5: "#757575",
			level6: "#999",
			level7: "#BBB",
			level8: "#DDD",
			level9: "#FFF",
			link: colors.azureRadiance,
			//...
			danger: colors.sunsetOrange,
			default: colors.boulder,
			primary: colors.azureRadiance,
			success: colors.mountainMeadow,
			warning: colors.corn
		},
		typo: {
			colors: {
				level1: "#000",
				level2: "#222",
				level3: "#444",
				level4: "#666",
				level5: "#757575",
				level6: "#999",
				level7: "#BBB",
				level8: "#DDD",
				level9: "#FFF"
			},
			fontFamilies: fonts,
			fontSizes: {
				default: "16px",
				h1: "28px",
				h2: "24px",
				large: "18px",
				larger: "20px",
				small: "14px",
				smaller: "12px"
			},
			lineHeight: 1.25
		},





		
		modal: {
			backdrop: {
				background: {
					colors: {
						overlay: transparentize(0.4, colors.tundora),
						plain: colors.white
					}
				}
			},
			background: {
				color: colors.white
			},
			header: {
				border: {
					color: colors.gallery
				},
				typo: {
					color: colors.tundora
				}
			},
			shadow: `0 2px 8px 0 ${transparentize(0.9, colors.black)}`
		}
	}
};
